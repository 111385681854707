<div class="row back">
    
    <div class="col-lg-6">
        <h3>Um serviço diferente!</h3>
        <img src="../../../assets/images/smartphoneapp.png">
    </div>

    <div class="bg col-lg-6">
        <div class="row">
            <div class="col-md-4">
                <a [routerLink]="['/inventario-extintores']" class="card-name wide column" >
                    <mat-card class="card-one">
                        <i class="fas fa-list-ol icon"></i>
                        <mat-card-content>
                            <p class="card-name" >Inventário Extintores</p> 
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/gerencial-extintores']" class="card-name wide column" >
                    <mat-card class="card-two">
                        <i class="fas fa-chart-area icon"></i>
                        <mat-card-content>
                           <p class="card-name" > Gerencial Extintores </p> 
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]= "['/checklist-extintores']" class="card-name wide column" >
                    <mat-card class="card-three">
                        <i class="fas fa-tasks icon"></i>
                        <mat-card-content>
                            <p class="card-name" > Checklist Extintores </p> 
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/retirada-extintores']" class="card-name column" >
                    <mat-card class="card-four">
                        <i class="fas fa-truck icon"></i>
                        <mat-card-content>
                            <p class="card-name" >Retirada Extintores</p> 
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/arquivo']" class="card-name column">
                    <mat-card class="card-five">
                        <i class="fas fa-download icon"></i>
                        <mat-card-content>
                            <p class="card-name" >Procurar Documentos</p>   
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/profile']" class="card-name column">
                    <mat-card class="card-six">
                        <i class="fas fa-address-card icon"></i>
                        <mat-card-content>
                            <p class="card-name" >Editar Profile</p> 
                        </mat-card-content>
                    </mat-card>
                </a>
            </div>
            
        </div>
    </div>
</div>
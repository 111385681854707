import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.stepe.html',
  styleUrls: ['./painel.stepe.css']
})
export class PainelComponent implements OnInit {

  

  constructor() { }

  ngOnInit() {
    
  }

}

import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../../model/empresa.model'
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import { Autenticacao } from '../../../config/autenticacao.service';
import { Router } from '@angular/router'
import { from } from 'rxjs';
@Component({
  selector: 'app-cadastro-empresas',
  templateUrl: './cadastro-empresas-stepe.html',
  styleUrls: ['./cadastro-empresas-stepe.css']
})

export class CadastroEmpresasComponent implements OnInit {

  public isLinear = true;
  private nivel = localStorage.getItem('nivel')

  // formGroups 

  formCodCliente : FormGroup;
  formInitial : FormGroup;
  formDados : FormGroup;

  constructor(
    private cadastrarEmpresa: Autenticacao,
    private _formBuilder : FormBuilder,
    private router: Router,
  ) { }

  public formulario: FormGroup = new FormGroup({
    'codigoInterno': new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    'razaoSocial': new FormControl(null, [Validators.required, Validators.minLength(4)]),
    'nomeFantasia': new FormControl(null, [Validators.required, Validators.minLength(4)]),
    'endereco': new FormControl(null, [Validators.required, Validators.minLength(4)]),
    'cidade': new FormControl(null, [Validators.required]),
    'checkBradesco': new FormControl(null),
    'predioBradesco': new FormControl(null)
  })

  ngOnInit() {
    this.validate(this.nivel)
    this.formCodCliente = this._formBuilder.group({
      codInterno : ['',[Validators.required,Validators.minLength(4)]]
    })
    this.formInitial =this._formBuilder.group({
      razaoSocial : ['',[Validators.required,Validators.minLength(4)]],
      nomeFantasia : ['',[Validators.required,Validators.minLength(4)]]
    })
    this.formDados = this._formBuilder.group({
      endereco : ['',[Validators.required,Validators.minLength(4)]],
      cidade : ['',[Validators.required,Validators.minLength(3)]]
    })
  }

  public validate(nivel){
    console.log(nivel)
    if(nivel !== 'Moderador'){
      if(nivel !== 'Admin'){
        this.router.navigate(['../'])
      }
    }
  }

  public efetuarCadastro(): void{
    console.log(this.formulario.value.checkBradesco)
      let empresa: Empresa = new Empresa(
        this.formCodCliente.value.codInterno,
        this.formInitial.value.razaoSocial,
        this.formInitial.value.nomeFantasia,
        this.formDados.value.endereco,
        this.formDados.value.cidade
      )
      console.log(empresa)
      this.cadastrarEmpresa.autenticarEmpresa(empresa)
    
  }
}

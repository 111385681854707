import { Component, OnInit } from '@angular/core';
import { Bd } from '../../../config/bd.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-gerencial-cliente',
  templateUrl: './gerencial-cliente.component.html',
  styleUrls: ['./gerencial-cliente.component.css']
})
export class GerencialClienteComponent implements OnInit {

  public filtroSub = false;

  public nivel: string = localStorage.getItem('nivel');
  public email: string = localStorage.getItem('email');
  public extintor: number;
  public qtdCk: number;
  public qtdRetirada: number;
  public media: number;
  public dados: string;
  public unidades: string;
  public unidadeData: string;
  public sublocacao: string;
  public ativaFiltro: boolean;
  public mediaArray = new Array();
  public data3 = new Array();
  public difInsp = new Array();
  public arrayRetirada = new Array();

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels: string[] = ['total', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData = [
    {data: [0], bezierCurve: false, label: 'Equipamentos Inspecionados', lineTension: 0},
    {data: [1], bezierCurve: false, label: 'Média', lineTension: 0},
    {data: [2], bezierCurve: false, label: 'Falta inspeção', lineTension: 0}
  ];

  public lineChartOpRetirada: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    steppedLine: true
  };
  public lineChartLabelsRetirada: string[] = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
  public lineChartTypeRetirada = 'line';
  public lineChartLegendRetirada = true;
  public lineChartDataRetirada: any[] = [
    {data: [0], bezierCurve: false, label: 'Equipamentos Retirados', lineTension: 0},
  ];

  public maskCodigo: any[] = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private bd: Bd
  ) { }

  public formulario: FormGroup = new FormGroup({
    codigoUnidade: new FormControl(null, [Validators.required]),
    sublocacao: new FormControl(null),
    checkSublocal: new FormControl(null, [Validators.requiredTrue])
  });

  ngOnInit(): void {
    this.consultaUnidade();
  }

  public mostrarSub(): void{
    this.ativaFiltro = this.formulario.value.checkSublocal;
  }

  public recuperarSublocacao(): void{
    this.bd.buscaSublocacao(this.formulario.value.codigoUnidade)
      .then((sublocacao: string) => {
        this.sublocacao = sublocacao;
        if (sublocacao.length !== 0){
          this.filtroSub = true;
        } else {
          this.filtroSub = false;
        }
      });
  }

  public qtdTotalExt(codigoInterno: string): void{

    if (this.formulario.value.checkSublocal === true) {
      this.bd.consultaExtintoreSublocacao(codigoInterno, this.formulario.value.sublocacao)
        .then((extintor: string) => {
          this.extintor = extintor.length;
          this.data3.push(extintor.length);
          this.mediaArray.push(extintor.length);
          this.difInsp.push(extintor.length);
        });
    }else{
      this.bd.consultaExtintores(codigoInterno)
      .then((extintor: string) => {
        this.extintor = extintor.length;
        this.data3.push(extintor.length);
        this.mediaArray.push(extintor.length);
        this.difInsp.push(extintor.length);
      });
    }
  }

  public consultaDadosUnidade(): void{
    this.bd.consultaEmpresaCodigo(this.formulario.value.codigoUnidade)
      .then((unidadeData: string) => {
      this.unidadeData = unidadeData;
    });
  }

  public consultaEmpresaCodigo(): void{
    this.data3 = [];
    this.mediaArray = [];
    this.arrayRetirada = [];
    this.difInsp = [];
    this.bd.consultaEmpresaCodigo(this.formulario.value.codigoUnidade)
      .then((dados: string) => {
        this.dados = dados;
      });
    this.randomize(this.formulario.value.codigoUnidade);
    this.randomizeRetirada(this.formulario.value.codigoUnidade);
  }

  // Consultar unidades de acesso do cliente
  public consultaUnidade(): void{
    this.bd.consultaUnidadesUser(this.email)
      .then((unidades: string) => {
        this.unidades = unidades;
      });
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public randomize(codigoInterno: string): void {
    this.qtdTotalExt(codigoInterno);
    let i = 1;
    while (i < this.barChartLabels.length){
        this.qtdChecklist(codigoInterno, this.barChartLabels[i], '2020');
        i++;
      }
  }

  public qtdChecklist(codigoInterno: string, mes: string, ano: string): void{
    if (this.formulario.value.checkSublocal === true){

      this.bd.consultaChecklistaSub(codigoInterno, mes, ano, this.formulario.value.sublocacao)
        .then((qtdCk: string) => {
          this.qtdCk = qtdCk.length;
          this.data3.push(qtdCk.length);
          const soma = this.data3.reduce((previous, current) => current += previous);
          this.media = soma / this.data3.length;
          this.mediaArray.push(this.media);
          const dif: number = this.extintor - qtdCk.length;
          this.difInsp.push(dif);

          const clone = JSON.parse(JSON.stringify(this.barChartData));
          clone[0].data = this.data3;
          clone[1].data = this.mediaArray;
          clone[2].data = this.difInsp;

          this.barChartData = clone;
        });

    }else{

      this.bd.consultaCheklist(codigoInterno, mes, ano)
      .then((qtdCk: string) => {
          this.qtdCk = qtdCk.length;
          this.data3.push(qtdCk.length);

          const soma = this.data3.reduce((previous, current) => current += previous);
          this.media = soma / this.data3.length;

          const dif = this.extintor - this.qtdCk;
          this.difInsp.push(dif);
          this.mediaArray.push(this.media);

          const clone = this.barChartData;
          clone[0].data = this.data3;
          clone[1].data = this.mediaArray;
          clone[2].data = this.difInsp;

          this.barChartData = clone;
      });

    }

  }

  public chartClickedRetirada(e: any): void{
    console.log(e);
  }
  public chartHoveredRetirada(e: any): void{
    console.log(e);
  }

  public randomizeRetirada(codigoInterno: string): void{
    let i = 0;
    console.log(formatDate(new Date(), 'yyyy', 'en'));
    while (i < this.lineChartLabelsRetirada.length){
      this.listaRetirada(codigoInterno, this.lineChartLabelsRetirada[i], '2020');
      i++;
    }
  }

  public listaRetirada(codigoInterno: string, mes: string, ano: string): void {
    this.bd.buscarRetiradaExtintor(codigoInterno, mes, ano)
      .then((qtdRetirada: string) => {
        this.qtdRetirada = qtdRetirada.length;
        this.arrayRetirada.push(qtdRetirada.length);
        const clone = this.lineChartDataRetirada;
        clone[0].data = this.arrayRetirada;
        this.lineChartDataRetirada = clone;
      });
  }

}

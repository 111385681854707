<h1 mat-dialog-title>Dados Empresa:</h1>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item> 
        <h6 class="empresa" >Codigo:</h6>
        <span class="example-spacer"></span>
        <p class="data-empresa">{{data[0].codigoInterno}}</p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> 
      <h6 class="empresa" >Razão Social:</h6>
      <span class="example-spacer"></span>
      <p class="data-empresa">{{data[0].razaoSocial}}</p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> 
      <h6 class="empresa" >Endereço:</h6>
      <span class="example-spacer"></span>
      <p class="data-empresa">{{data[0].endereco}}</p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> 
      <h6 class="empresa" >Cidade:</h6>
      <span class="example-spacer"></span>
      <p class="data-empresa">{{data[0].cidade}}</p>
      </mat-list-item>
  </mat-list>

  <div class="row" style="margin-top: 30px; margin-bottom: 30px;">
      <div class="col">Sublocação:</div> 
    </div>
    <div class="edit" *ngFor="let sublocal of sublocacao">
      <!--Modo sem edição-->
      <div class="row edit" *ngIf="editControl !== sublocal.codigo">
        <b>{{sublocal.descricao}}</b>
        <span class="example-spacer"></span>
        <button mat-icon-button color="primary" (click)="editControl = sublocal.codigo">
          <mat-icon class="deleteIcon">edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="excluirSublocacao(sublocal.codigo)">
          <mat-icon class="deleteIcon">delete</mat-icon>
        </button>
      </div>
    
    <!--Modo com edição-->
    
        <div class="edit" *ngIf="editControl === sublocal.codigo">
            <mat-form-field class="example-full-width" [formGroup]="formulario">
                <input matInput
                  aria-label="campousuario"
                  #editSub
                  value="{{sublocal.descricao}}">
                  <span class="example-spacer"></span>
                  <button  matSuffix mat-icon-button color="primary" (click)="editarSublocacao(editSub.value, sublocal.codigo)">
                    <mat-icon aria-label="Example icon-button with a heart icon" color="green">check</mat-icon>
                  </button>
                  <button  matSuffix mat-icon-button color="danger" (click)="editControl = ''">
                    <mat-icon aria-label="Example icon-button with a heart icon" color="green">cancel</mat-icon>
                  </button>
            </mat-form-field> 
        </div>
    </div>
  <div class="row">
    <mat-form-field class="example-full-width" [formGroup]="formulario">
      <input matInput
        aria-label="campousuario"
        formControlName="sublocal"
        
        placeholder="Digite a sublocação">
        <span class="example-spacer"></span>
        
        <button  matSuffix mat-icon-button color="primary" (click)="adcionarSublocacao()">
          <mat-icon aria-label="Example icon-button with a heart icon" color="green">check</mat-icon>
        </button>
    </mat-form-field> 
  </div>
</div>
<div mat-dialog-actions>

  <span class="example-spacer"></span>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>



import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from '../environments/environment';
import {interval as observableInterval,  Observable ,  Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { Autenticacao } from './config/autenticacao.service';
import { Router } from '@angular/router';
import 'rxjs/Rx';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';
  public nomeUsuario: string;
  public tipoUsuario: string;
  public uidFirebase: string;

  constructor(
    private autenticacao: Autenticacao,
    private router: Router
  ){}

  ngOnInit(): void {
    firebase.initializeApp(environment.firebase);

    this.recuperarUser();
  }

  public recuperarUser(): void {

    const acompanhamentoDados = observableInterval(500);

    const continua = new Subject();

    continua.next(true);

    acompanhamentoDados.pipe(
      takeUntil(continua))
      .subscribe(() => {

        this.nomeUsuario = localStorage.getItem('nome');
        this.tipoUsuario = localStorage.getItem('nivel');
        this.uidFirebase = localStorage.getItem('idToken');

        if (this.uidFirebase === null){
          this.router.navigate(['../']);
        }
        if (this.tipoUsuario !== null ) {
          continua.next(false);
        }
      });
    }

    public sair(): void {
      this.autenticacao.sair();
      this.nomeUsuario = null;
      this.tipoUsuario = null;
      this.uidFirebase = null;
    }
}

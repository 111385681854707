import { Routes } from '@angular/router'
import { AutenticacaoGuard } from './autenticacao-guard.service';
import { LoginComponent } from '../login/login.component';
import { CadastroComponent } from '../home/usuarios/cadastro-usuario/cadastro.component';
import { ListaEmpresasComponent } from '../home/empresas/lista-empresas/lista-empresas.component';
import { CadastroEmpresasComponent } from '../home/empresas/cadastro-empresas/cadastro-empresas.component';
import { CadastroClienteComponent } from '../home/usuarios/cadastro-cliente/cadastro-cliente.component';
import { ProfileComponent } from '../home/profile/profile.component';
import { EntidadesComponent } from '../home/usuarios/entidades/entidades.component';
import { PainelComponent } from '../home/painel/painel.component';
import { CronogramaComponent } from '../home/usuarios/cronograma/cronograma.component';
import { GerencialClienteComponent } from '../home/extintores/gerencial-cliente/gerencial-cliente.component';
import { IventarioComponent } from '../home/extintores/iventario/iventario.component';
import { RelClientesComponent } from '../home/extintores/rel-clientes/rel-clientes.component';
import { RetiradaExtComponent } from '../home/extintores/retirada-ext/retirada-ext.component';
import { ArquivoClienteComponent } from '../home/documents/arquivo-cliente/arquivo-cliente.component';
import { UploadPdfComponent } from '../home/documents/upload-pdf/upload-pdf.component';
import { FbtransferComponent } from './fbtransfer/fbtransfer.component';

export const ROUTES: Routes = [
    {path: '', component: LoginComponent},
    {path: 'home', component: PainelComponent, canActivate: [ AutenticacaoGuard]},
    {path: 'cadastro', component: CadastroComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'lista-empresas', component: ListaEmpresasComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'cadastro-empresa', component: CadastroEmpresasComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'cadastro-cliente', component: CadastroClienteComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'profile', component: ProfileComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'entidades', component: EntidadesComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'cronograma', component: CronogramaComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'gerencial-extintores', component: GerencialClienteComponent, canActivate: [ AutenticacaoGuard ]},
    {path: 'inventario-extintores', component: IventarioComponent, canActivate: [AutenticacaoGuard ]},
    {path: 'checklist-extintores', component: RelClientesComponent, canActivate: [AutenticacaoGuard ]},
    {path: 'retirada-extintores', component: RetiradaExtComponent, canActivate: [AutenticacaoGuard ]},
    {path: 'arquivo', component: ArquivoClienteComponent, canActivate: [AutenticacaoGuard ]},
    {path: 'upload', component: UploadPdfComponent, canActivate: [AutenticacaoGuard ]},
    {path: 'fbtransfer', component: FbtransferComponent, canActivate: [AutenticacaoGuard ]}
];

<!--Inicio Cabeçalho-->
    <div class="ui container">
        <form [formGroup]="formulario">
            <div class="row">
                <div class="col-md caixa1">
                <h6>Procure pelo empresa desejada:<br> </h6>
            
                    <mat-form-field>
                            <mat-label>Filtrar por:</mat-label>
                            <mat-select formControlName="selectEmpresa">
                                <mat-option value="codigoInterno">Código</mat-option>
                                <mat-option value="razaoSocial">Razão Social</mat-option>
                                <mat-option value="nomeFantasia">Nome Fantasia</mat-option>
                            </mat-select>
                    </mat-form-field>
            
            
                    <mat-form-field class="example-full-width">
                            <input matInput placeholder="Consulta Empresas"
                            #termoEmpresa = ""
                            (keyup)="atualizaListaEmpresa(termoEmpresa.value)"
                            formControlName="termoEmpresa">
                    </mat-form-field>
            
                </div>
            </div>
        </form>
    </div>

    <!--Fim Cabeçalho-->
    <!--Inicil Tabela-->

    <div class="sheetArea">
        <dx-data-grid
          #grid
          (onToolbarPreparing)="onToolbarPreparing($event)"
          id="gridContainer"
          [dataSource]="empresas"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [rowAlternationEnabled]="true"
          [columnAutoWidth]="false"
          [showBorders]="true">
                  
          <dxo-paging name="paging" [pageSize]="10" [enabled]="false"></dxo-paging>
            
          <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 20, 50]"
              [showNavigationButtons]="true">
          </dxo-pager>
          <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        
          <dxo-export [enabled]="true"></dxo-export>
              <dxi-column dataField="codigoInterno" dataType="text"[fixed]="true" caption="Nome"></dxi-column>
              <dxi-column dataField="razaoSocial"  dataType="text" caption="Email" ></dxi-column>
              <dxi-column dataField="nomeFantasia" dataType="text" caption="Nível" ></dxi-column>
              <dxi-column dataField="endereco" dataType="text" caption="Endereço" ></dxi-column>
              <dxi-column dataField="cidade" dataType="text" caption="Cidade" ></dxi-column>
              <dxi-column dataField="codigoInterno"caption="Editar" cellTemplate="cellTemplate">
                <div *dxTemplate="let data of 'cellTemplate'">
                    <button class="btnicon" mat-icon-button color="primary" (click)="pesquisaEmpresa(data.value)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>  
              </dxi-column>
                      
              <dxo-summary>
                <dxi-total-item
                  column="nome"
                  summaryType="sum"
                  displayFormat="Total: {0}">
                </dxi-total-item>
              </dxo-summary>
        </dx-data-grid>   
    </div>
    <!--Fim tabela-->


import {interval as observableInterval, Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Bd } from '../../../config/bd.service';
import { Progresso } from '../../../config/progresso.service';
import 'rxjs/Rx';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.css']
})
export class UploadPdfComponent implements OnInit {

  relatorioPDF: any;
  progressoPublicacao = 'pendente';
  porcentagemUpload: number;
  arquivos: any;
  razaoSocial: string;
  nivel = localStorage.getItem('nivel');
  formCodEmp: FormGroup;
  formSelect: FormGroup;
  formUnidade: FormGroup;
  formUpload: FormGroup;
  dados: string;

  public formulario: FormGroup = new FormGroup({
    tipoRelatorio : new FormControl(null, [Validators.required]),
    mes : new FormControl(null, [Validators.required]),
    ano : new FormControl(null, [Validators.required]),
    codigoEmpresa: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    unidade: new FormControl(null, [Validators.required, Validators.minLength(2)])
  });

  public formularioBusca: FormGroup = new FormGroup({
    tipoRelatorioBusca : new FormControl(null),
    mesBusca: new FormControl(null),
    anoBusca: new FormControl(null)
  });

  constructor(
    private bd: Bd,
    private progresso: Progresso,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.validate(this.nivel);
    this.formCodEmp = this.formBuilder.group({
      codEmpresa : ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    });
    this.formSelect = this.formBuilder.group({
      tipoRelatorio : ['', [Validators.required]],
      mes : ['', [Validators.required]],
      ano : ['', [Validators.required]],
    });
    this.formUnidade = this.formBuilder.group({
      unidade : ['', [Validators.required, Validators.minLength(2)]]
    });
    this.formUpload = this.formBuilder.group({
      upload : ['', [Validators.required]]
    });
  }

  public validate(nivel): void{
    console.log(nivel);
    if (nivel !== 'Moderador'){
      if (nivel !== 'Admin'){
        this.router.navigate(['../']);
      }
    }
  }

  public preparaPdfUpload(event: Event): void{
      this.relatorioPDF = (event.target as HTMLInputElement).files;
  }

  public carregarOutroArquivo(): void{
    this.formulario.reset();
    this.progressoPublicacao = 'pendente';
  }

  public buscarEmpresa(): void {
    console.log("Buscando pelo código: " + this.formCodEmp.value.codEmpresa)
    this.bd.consultaEmpresaCodigo(
      this.formCodEmp.value.codEmpresa
    ).then((dados: string) => {
      this.dados = dados;
    });

    firebase.database().ref(`empresas/${this.formulario.value.codigoEmpresa}`)
      .once('value')
      .then((snapshot: any) => {
        this.razaoSocial = snapshot.val();
      });
  }

  public atualizarLista(): void{
    console.log("Buscando pelo código: " + this.razaoSocial)
    if ( this.razaoSocial === null ) {
      this.arquivos = null;
    } else {
      this.bd.consultaArquivo(
          this.formCodEmp.value.codEmpresa,
          this.formSelect.value.tipoRelatorio,
          this.formSelect.value.mes,
          this.formSelect.value.ano)
      .then((arquivos: any) => {
        this.arquivos = arquivos;
      });
    }
  }

  public publicar(): void{
    this.progressoPublicacao = 'andamento';
    if (this.razaoSocial !== null){
      this.bd.publicar({
        codigoEmpresa: this.formCodEmp.value.codEmpresa,
        tipoRelatorio: this.formSelect.value.tipoRelatorio,
        mes: this.formSelect.value.mes,
        ano: this.formSelect.value.ano,
        unidade: this.formUnidade.value.unidade,
        relatorioPDF: this.relatorioPDF[0]
      });

      const acompanhamentoUpload = observableInterval(2500);
      const continua = new Subject();
      continua.next(true);
      acompanhamentoUpload.pipe(
        takeUntil(continua))
        .subscribe(() => {
        this.porcentagemUpload = Math.round((this.progresso.estado.bytesTransferred / this.progresso.estado.totalBytes) * 100);

        if (this.progresso.status === 'concluido'){
          this.progressoPublicacao = 'concluido';
          continua.next(false);
        }
      });
    } else {
      alert('código de empresa incorreto');
    }
  }

  public deleteArq(key: string): void{
    alert('O arquivo' + this.formSelect.value.tipoRelatorio + ' ' + this.formSelect.value.mes + ' foi deletado com sucesso!');
    this.bd.deletaArquivo(
      this.formCodEmp.value.codEmpresa,
      this.formSelect.value.tipoRelatorio,
      this.formSelect.value.ano,
      this.formSelect.value.mes,
      key
    );
    this.atualizarLista();
  }

}


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../model/usuario.model';
import { Empresa } from '../model/empresa.model';
import { Bd } from './bd.service';
import * as firebase from 'firebase';




@Injectable()
export class Autenticacao{

    public tokenId: string;
    public nivelAcesso: string;
    public tipoStorage: any;
    public nomeStorage: any;

    constructor(
        private router: Router,
        private bd: Bd
    ){}

    public cadastrarUsuario(usuario: Usuario): void {
        console.log(usuario);
        firebase.auth().createUserWithEmailAndPassword(
            usuario.email,
            usuario.senha)
            .then((resposta: any) => {
                firebase.database().ref(`usuarios/${btoa(usuario.email)}`)
                    .set( usuario ).then((result: string) => console.log(result))

                alert('Usuario Criado com sucesso:' + resposta);
                this.router.navigate(['/home']);
            })
            .catch((error: Error) => {
                alert('Usuário já cadastrado ' + error);
            });
    }

    public autenticar(email: string, senha: string): void {

        firebase.auth().signInWithEmailAndPassword(email, senha)
            .then((resposta: any) => {
                firebase.auth().currentUser.getIdToken()
                    .then((idToken: string) => {
                        this.tokenId = idToken;
                        localStorage.setItem('idToken', idToken);
                        localStorage.setItem('email', email);
                        this.bd.consultaNívelUsuario();
                        this.router.navigate(['../home']);
                    });

            })
            .catch((error: Error) => {
                alert('Usuário ou senha inválidos');
            });
    }

    public autenticarEmpresa(empresa: Empresa): void {
        firebase.database().ref(`empresas/${empresa.codigoInterno}`)
            .set(empresa);
        alert('Empresa Cadastrada com sucesso');
        this.router.navigate(['../home']);
    }

    public autenticado(): boolean {
        if (this.tokenId === undefined && localStorage.getItem('idToken') != null){
            this.tokenId = localStorage.getItem('idToken');
        }
        return this.tokenId !== undefined;
    }

    public sair(): void{
        firebase.auth().signOut()
            .then(() => {
                this.router.navigate(['/']);
                this.tokenId = undefined;
                localStorage.removeItem('idToken');
                localStorage.removeItem('email');
                localStorage.removeItem('tipo');
                localStorage.removeItem('nome');
                localStorage.removeItem('codEmpresa');
                localStorage.removeItem('nivel');
            });
    }

    public resetSenha(email: string): void{
        firebase.auth().sendPasswordResetEmail(email);
        alert('Um e-mail foi enviado ao usuário');
    }

    public adicionarUnidade(email: string, empresas: Empresa): void{
        firebase.database().ref(`usuarios/${btoa(email)}/unidade/${empresas.codigoInterno}`)
            .set(empresas);
        alert('Empresa Cadastrada com sucesso');

    }

}


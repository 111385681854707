import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Usuario } from '../../../model/usuario.model'
import { Autenticacao } from '../../../config/autenticacao.service'
import { Bd } from '../../../config/bd.service'

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro-usuario-stepe.html',
  styleUrls: ['./cadastro-usuario-stepe.css']
})
export class CadastroComponent implements OnInit {

  public empresas: string
  public codEmpresa: string = '0001'
  public dados: any
  public isLinear = true;
  private nivel = localStorage.getItem('nivel')
  
  //formGroups
  formNivel : FormGroup;
  formInitial : FormGroup;
  formPass : FormGroup;

  constructor(
    private autenticacao: Autenticacao,
    private bd: Bd,
    private router: Router,
    private _formBuilder : FormBuilder,
  ) { }

  public formulario: FormGroup = new FormGroup({
    'email': new FormControl(null, [Validators.required, Validators.minLength(7), Validators.email]),
    'senha': new FormControl(null, [Validators.required, Validators.minLength(6)]),
    'nivel': new FormControl(null, [Validators.required]),
    'nome': new FormControl(null, [Validators.required, Validators.minLength(4)])
    

  })

  ngOnInit() {
    this.validate(this.nivel)
    this.formNivel = this._formBuilder.group({
      nivel : ['',[Validators.required,]]
    })
    this.formInitial = this._formBuilder.group({
      nome : ['',[Validators.required,Validators.minLength(3)]],
      email :['',[Validators.required,Validators.email]]
    })
    this.formPass = this._formBuilder.group({
      senha : ['',[Validators.required,Validators.minLength(6)]],
      confSenha : ['',[Validators.required,Validators.minLength(6)]]
    })
   
  }

  public validate(nivel){
    console.log(nivel)
    if(nivel !== 'Moderador'){
      if(nivel !== 'Admin'){
        this.router.navigate(['../'])
      }
    }
  }

  public cadastrarUsuario(): void{

      let usuario: Usuario = new Usuario(
      this.formInitial.value.email,
      this.formPass.value.senha,
      '0001',
      this.formNivel.value.nivel,
      this.formInitial.value.nome,
    )

    this.autenticacao.cadastrarUsuario(usuario)
  }
  
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Bd } from '../config/bd.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';


import { Autenticacao } from '../config/autenticacao.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  public estado = 'fim';
  public uidFirebase: string = localStorage.getItem('idToken');
  public esqueceuSenha = false;

  public formulario: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.email]),
    senha: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]),
  });

  constructor(
    private autenticacao: Autenticacao,
    private bd: Bd,
    private router: Router,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.verificarUsuarioLogado();
  }

  public autenticar(): void{
      this.autenticacao.autenticar(
      this.formulario.value.email,
      this.formulario.value.senha,
      );
      this.appComponent.recuperarUser();
  }

  public verificarUsuarioLogado(): void{
    if (this.uidFirebase != null){
      this.router.navigate(['../home']);
    }
  }
  public exibirRecuperacaoSenha(): void{
    this.esqueceuSenha = true;
  }

  public exibirLogin(): void{
    this.esqueceuSenha = false;
  }

  public recuperarSenha(): void{
    this.autenticacao.resetSenha(this.formulario.value.email);
  }
}

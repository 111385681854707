<div>
    <div class="row" >
        <mat-card class="card-cadastro" >
                <mat-card-title> Cadastro de cliente  </mat-card-title>    
      <mat-vertical-stepper [linear]="isLinear" #stepper class="stepper" >
            <mat-step [stepControl]="formEmpresa" >
              <form [formGroup]="formEmpresa">
                <ng-template matStepLabel>Codigo Empresa</ng-template>
                <mat-form-field>
                  <input matInput placeholder="Codigo Empresa " formControlName="codEmpresa" required (change)="recuperarRazaoSocial()" maxlength="4">
                </mat-form-field>
                <div class="card" style="width: 18rem;"  *ngFor="let dado of dados">
                    <div class="card-body">
                        <h6 class="card-title">{{dado.razaoSocial}}</h6>
                        <h6 class="card-subtitle mb-2 text-muted">{{dado.nomeFantasia}}</h6>
                        <p class="card-text">{{dado.endereco}}</p>
                    </div>
                </div>  
                <div>
                  <button mat-button matStepperNext>Proximo</button>
                </div>
              </form>
                </mat-step>
            <mat-step [stepControl]="formInitial">
              <form [formGroup]="formInitial">
                <ng-template matStepLabel>Insira um E-mail</ng-template>
                <mat-form-field>
                  <input matInput placeholder="Digite o nome" formControlName="nome" required>
                </mat-form-field>
                <div>
                    <mat-form-field>
                      <input matInput placeholder="Digite um E-mail" formControlName="email" required type="email" >
                    </mat-form-field>
                </div>
                <div>
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-button matStepperNext>Proximo</button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="formPass" >
                <form [formGroup]="formPass"> 
                    <ng-template matStepLabel>Insira uma Senha</ng-template>
                    <div>
                        <mat-form-field>
                                <input matInput placeholder="Senha" formControlName="senha" type="password" >
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                                <input matInput placeholder="Confirmar a Senha"  formControlName="confSenha" type="password"  >
                        </mat-form-field>
                    </div>
                    <button mat-button (click)="cdtCliente()" *ngIf="formPass.value.senha === formPass.value.confSenha && formPass.value.senha !== ''" >  Cadastrar</button>
                </form>
            </mat-step>
          </mat-vertical-stepper>
        </mat-card>
    </div>
</div>
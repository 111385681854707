import { Empresa } from './empresa.model';
import { Acesso } from './acesso.model';

export class Usuario {
    constructor(
        public email: string,
        public senha: string,
        public codigoEmpresa: string,
        public nivel: string, //Preparar para substuição por acesso
        public nome: string,
    ){}
    public id: string;
    public acesso: Acesso;
    public unidade: Empresa;
}

<!--Acesso Admin -->

<div class="bg-img" *ngIf=" tipoUsuario === 'Admin' || tipoUsuario === 'Moderador'">

  <div class="row">
    <div class="col-sm-12">
      <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <a class="navbar-brand" href="#">
          <img src="../../assets/logo-proteci-neg.png">
        </a>
        <button class="navbar-toggler justify-content-right" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto nav" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link text-light" 
                  id="painel-tab" 
                  data-toggle="tab" 
                  href="#painel" 
                  role="tab" 
                  aria-controls="painel" 
                  aria-selected="true">Painel</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" 
                style="color: white" 
                href="#" 
                id="navbarDropdown" 
                role="button" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false">Usuários</a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" 
                  id="usuarios-tab" 
                  data-toggle="tab" 
                  href="#usuarios" 
                  role="tab" 
                  aria-controls="Usuarios" 
                  aria-selected="true">Lista Usuários</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" 
                  id="cronograma-tab" 
                  data-toggle="tab" 
                  href="#cronograma" 
                  role="tab" 
                  aria-controls="cronograma" 
                  aria-selected="true">Cronograma</a>
              </div>     
            </li>
            <li class="nav-item">
              <a class="nav-link text-light" 
                  id="empresas-tab" 
                  data-toggle="tab" 
                  href="#empresas" 
                  role="tab" 
                  aria-controls="empresas" 
                  aria-selected="true">Empresas</a> 
            </li>

            <!-- Aba Extintor -->
            <li class="nav-item dropdown" dropdown>
              <a  class="nav-link dropdown-toggle" 
                  style="color: white" 
                  href="#" 
                  id="navbarDropdown" 
                  role="button" 
                  data-toggle="dropdown" 
                  aria-haspopup="true" 
                  aria-expanded="false">Extintor</a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" 
                    id="gerecial-cliente-tab"   
                    data-toggle="tab" 
                    href="#gerecial-cliente" 
                    role="tab" 
                    aria-controls="gerecial-cliente" 
                    aria-selected="true">Gerencial Cliente</a>
                <a class="dropdown-item" 
                    id="inventario-tab"   
                    data-toggle="tab" 
                    href="#inventario" 
                    role="tab" 
                    aria-controls="inventario" 
                    aria-selected="true">Inventário Extintor</a>
                <a class="dropdown-item"
                   id="retirada-ext-tab"
                   data-toggle="tab"
                   href="#retirada-ext"
                   hole="tab"
                   aria-controls="retirada-ext"
                   aria-selected="true">Retirada de equipamentos</a>    

                <div class="dropdown-divider"></div>
                <a class="dropdown-item" 
                    id="checklist-tab" 
                    data-toggle="tab" 
                    href="#checklist" 
                    role="tab" 
                    aria-controls="checklist" 
                    aria-selected="true">Check List</a>
                <!--<a class="dropdown-item" href="#">Something else here</a>-->
              </div>

            <li class="nav-item">
              <a class="nav-link text-light" 
                  id="uploadPDF-tab" 
                  data-toggle="tab" 
                  href="#uploadPDF" 
                  role="tab" 
                  aria-controls="uploadPDF" 
                  aria-selected="true">UpLoad</a>
            </li>          
          </ul>
        </div>

        <div class="dadosUser">
          <img class="userimg" src="../../assets/user.png" alt="">
          <a class="textUser" routerLink="/profile">{{nomeUsuario}}</a><br/>
        </div>

        <button class="btn btn-primary" (click)="sair()" >Logout</button>

      </nav>
    </div>
  </div>  

  <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="painel" role="tabpanel" aria-labelledby="painel-tab">
        <app-painel></app-painel>
      </div>
      
      <div class="tab-pane fade" id="gerecial-cliente" role="tabpanel" aria-labelledby="gerecial-cliente-tab">
        <app-gerencial-cliente></app-gerencial-cliente>
      </div>

      <div class="tab-pane fade" id="inventario" role="tabpanel" aria-labelledby="inventario-tab">
        <app-iventario></app-iventario>
      </div>

      <div class="tab-pane fade" id="checklist" role="tabpanel" aria-labelledby="checklist-tab">
        <app-rel-clientes></app-rel-clientes>
      </div>

      <div class="tab-pane fade" id="retirada-ext" role="tabpanel" aria-labelledby="retirada-ext-tab">
        <app-retirada-ext></app-retirada-ext>
      </div>

      <div class="tab-pane fade" id="empresas" role="tabpanel" aria-labelledby="empresas-tab">
        <app-lista-empresas></app-lista-empresas>
      </div>
      
      <div class="tab-pane fade" id="usuarios" role="tabpanel" aria-labelledby="usuarios-tab">
        <app-entidades></app-entidades>
      </div>
     
      <div class="tab-pane fade" id="cronograma" role="tabpanel" aria-labelledby="cronograma-tab">
        <app-cronograma></app-cronograma>
      </div>
      
      <div class="tab-pane fade" id="uploadPDF" role="tabpanel" aria-labelledby="uploadPDF-tab">
        <app-upload-pdf></app-upload-pdf>
      </div>
      
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <app-profile></app-profile>
      </div>


  </div>
</div>


<!-- Acesso Cliente -->
<div *ngIf=" tipoUsuario === 'Cliente' ">
  <div class="row" >
    <div class="col-sm-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-dark fixed-top">

        <button class="navbar-toggler justify-content-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto nav" id="myTab" role="tablist">
            <a class="navbar-brand" href="#">
              <img src="../../assets/logo-proteci-neg.png">
            </a>
            <li class="nav-item">
              <a class="nav-link text-light" 
                 id="painel-tab" 
                 data-toggle="tab" 
                 href="#painel" 
                 role="tab" 
                 aria-controls="painel" 
                 aria-selected="true">Arquivos</a>
            </li> 
            <!--<li class="nav-item">
              <a class="nav-link text-light" id="iventario-tab" data-toggle="tab" href="#iventario" role="tab" aria-controls="iventario" aria-selected="true">Iventário</a>
            </li>--> 
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" 
                 style="color: white" 
                 href="#" 
                 id="navbarDropdown" 
                 role="button" 
                 data-toggle="dropdown" 
                 aria-haspopup="true" 
                 aria-expanded="false">
                Extintor
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" 
                  id="gerecial-cliente-tab"   
                  data-toggle="tab" 
                  href="#gerecial-cliente" 
                  role="tab" 
                  aria-controls="gerecial-cliente" 
                  aria-selected="true">Gerencial Cliente</a>
                <a class="dropdown-item" 
                  id="inventario-tab" 
                  data-toggle="tab" 
                  href="#inventario" 
                  role="tab" 
                  aria-controls="inventario" 
                  aria-selected="true">Inventário</a>
                  <a class="dropdown-item" 
                   id="checklist-tab"  
                   data-toggle="tab"
                   href="#checklist" 
                   role="tab" 
                   aria-controls="checklist" 
                   aria-selected="false">
                   Check List
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                 id="retirada-ext-tab"
                 data-toggle="tab"
                 href="#retirada-ext"
                 hole="tab"
                 aria-controls="retirada-ext"
                 aria-selected="true">Retirada de equipamentos</a>  
                
              </div>
          </ul>
        </div>

        <div class="dadosUser">
          <img class="userimg" src="../../assets/user.png" alt="">
          <span class="textUser" routerLink="/profile" >{{nomeUsuario}}</span> <br/>
        </div>

        <button class="btn btn-primary" (click)="sair()" >Logout</button>

      </nav>
    </div>
  </div>  

  <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="painel" role="tabpanel" aria-labelledby="painel-tab">
        <app-arquivo-cliente></app-arquivo-cliente>
      </div>
      
      <div class="tab-pane fade" id="gerecial-cliente" role="tabpanel" aria-labelledby="gerecial-cliente-tab">
        <app-gerencial-cliente></app-gerencial-cliente>
      </div>
      
      <div class="tab-pane fade" id="inventario" role="tabpanel" aria-labelledby="inventario-tab">
        <app-iventario></app-iventario>
      </div>
      
      <div class="tab-pane fade" id="checklist" role="tabpanel" aria-labelledby="checklist-tab">
        <app-rel-clientes></app-rel-clientes>
      </div>

      <div class="tab-pane fade" id="retirada-ext" role="tabpanel" aria-labelledby="retirada-ext-tab">
          <app-retirada-ext></app-retirada-ext>
      </div>
  </div>

</div>

<div *ngIf=" tipoUsuario === null ">

  <button class="btn btn-primary"> acessar meu Painel </button>

</div>




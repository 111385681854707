import { Usuario } from './usuario.model';

export class Extintor {
    constructor(
        public codProtecin: string,
        public codigoEmpresa: string,
        public dataLancamento: Date,
        public tipo: string,
        public capacidade: number,
        public unidade: string ,
        public localizacao: string,
        public dataRecarga: Date,
        public dataTeste: Date,
        public dataProximaRecarga: Date,
        public dataProximoTeste: Date,
        public dataUltimaInspecao: Date,
        public seloInMetro: string,
        public numeroCliente: number,
        public numeroFabricante: number,
        public subLocacao: string,
        public usuario: Usuario[]
    ){}
}

import { Component, OnInit } from '@angular/core';
import { Autenticacao } from '../../config/autenticacao.service'


@Component({
  selector: 'app-profile',
  templateUrl: './profile-stepe.html',
  styleUrls: ['./profile-stepe.css']
})
export class ProfileComponent implements OnInit {

  public nome: string = localStorage.getItem('nome')
  public email: string = localStorage.getItem('email')
  public codEmpresa: string = localStorage.getItem('codEmpresa')
  public estadoSenha: boolean = true

  constructor(
    private autenticacao: Autenticacao
  ) { }

  ngOnInit() {
    //console.log(this.estadoSenha)
  }

  public resetSenha(): void {
    this.autenticacao.resetSenha(this.email)
    this.estadoSenha = false
    //console.log(this.estadoSenha)
  }


}

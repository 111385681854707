import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { FlexLayoutModule } from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DxDataGridModule, DxTemplateModule, } from 'devextreme-angular';
import { DxButtonModule, DxSelectBoxModule, DxCheckBoxModule } from 'devextreme-angular';

import { ChartsModule } from 'ng2-charts';

import { TextMaskModule} from 'angular2-text-mask';

import { ROUTES } from './config/app.routes';
import { Autenticacao } from './config/autenticacao.service';
import { AutenticacaoGuard} from './config/autenticacao-guard.service';
import { Bd } from './config/bd.service';
import { FirestoreService } from './config/firestore.service';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';

import { AppComponent } from './app.component';
import { TopoComponent } from './topo/topo.component';
import { LoginComponent } from './login/login.component';
import { CadastroComponent } from './home/usuarios/cadastro-usuario/cadastro.component';
import { HomeComponent } from './home/home.component';
import { ListaEmpresasComponent } from './home/empresas/lista-empresas/lista-empresas.component';
import { RodapeComponent } from './rodape/rodape.component';
import { PainelComponent } from './home/painel/painel.component';
import { EntidadesComponent } from './home/usuarios/entidades/entidades.component';
import { CadastroEmpresasComponent } from './home/empresas/cadastro-empresas/cadastro-empresas.component';
import { CadastroClienteComponent } from './home/usuarios/cadastro-cliente/cadastro-cliente.component';
import { RelClientesComponent } from './home/extintores/rel-clientes/rel-clientes.component';
import { UploadPdfComponent } from './home/documents/upload-pdf/upload-pdf.component';
import { Progresso } from './config/progresso.service';
import { ArquivoClienteComponent } from './home/documents/arquivo-cliente/arquivo-cliente.component';
import { ProfileComponent } from './home/profile/profile.component';
import { IventarioComponent } from './home/extintores/iventario/iventario.component';
import { CronogramaComponent } from './home/usuarios/cronograma/cronograma.component';
import { RetiradaExtComponent } from './home/extintores/retirada-ext/retirada-ext.component';
import { GerencialClienteComponent } from './home/extintores/gerencial-cliente/gerencial-cliente.component';
import { EditEmpresaComponent } from './dialogs/edit-empresa/edit-empresa.component';
import { FbtransferComponent } from './config/fbtransfer/fbtransfer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AppComponent,
    TopoComponent,
    LoginComponent,
    CadastroComponent,
    HomeComponent,
    ListaEmpresasComponent,
    RodapeComponent,
    PainelComponent,
    EntidadesComponent,
    CadastroEmpresasComponent,
    CadastroClienteComponent,
    RelClientesComponent,
    UploadPdfComponent,
    ArquivoClienteComponent,
    ProfileComponent,
    IventarioComponent,
    CronogramaComponent,
    RetiradaExtComponent,
    GerencialClienteComponent,
    EditUserComponent,
    EditEmpresaComponent,
    FbtransferComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES),
    ChartsModule,
    TextMaskModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    DxDataGridModule,
    DxTemplateModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    MatListModule,
    MatStepperModule,
    MatSelectModule,
    MatGridListModule,
    MaterialFileInputModule,
    MatTabsModule,
    MatAutocompleteModule,
    AngularFireModule.initializeApp(environment.firebase, 'sistemaprotecin'),
    AngularFirestoreModule,
    NgbModule
  ],
  providers: [ Autenticacao, AutenticacaoGuard, Bd, Progresso, FirestoreService],
  bootstrap: [AppComponent],
  entryComponents: [EditUserComponent, EditEmpresaComponent]
})
export class AppModule { }


import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms'
import { Usuario } from '../../../model/usuario.model'
import { Autenticacao } from '../../../config/autenticacao.service'
import { Bd } from '../../../config/bd.service'
import * as firebase from 'firebase'
import { Router } from '@angular/router'

@Component({
  selector: 'app-cadastro-cliente',
  templateUrl: './cadastro-cliente-stepe.html',
  styleUrls: ['./cadastro-cliente-stepe.css']
})

export class CadastroClienteComponent implements OnInit {

  public empresas: string
  public email: string
  public dados: any
  public razaoSocial: string
  public nivel: string = 'Cliente'
  public isLinear = true;
  private nvl = localStorage.getItem('nivel')

  // formGroups 

  formEmpresa : FormGroup;
  formInitial : FormGroup;
  formPass : FormGroup;


  constructor(
    private bd: Bd,
    private autenticacao: Autenticacao,
    private _formBuilder : FormBuilder,
    private router :Router
  ) { }

  public formulario: FormGroup = new FormGroup({
    'email': new FormControl(null, [Validators.required, Validators.email]),
    'senha': new FormControl(null, [Validators.required, Validators.minLength(6)]),
    'codEmpresa': new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    'nome': new FormControl(null, [Validators.required, Validators.minLength(4)]),
    'nivel': new FormControl(null, [Validators.required])
  })

  ngOnInit() {
      this.validate(this.nvl)
      this.listarEmpresas()
      this.formEmpresa = this._formBuilder.group({
        codEmpresa : ['',[Validators.required, Validators.minLength(4)]]
      })
      this.formInitial = this._formBuilder.group({
        nome : ['',[Validators.required, Validators.minLength(3)]],
        email : ['',[Validators.required,Validators.email]]
      })
      this.formPass = this._formBuilder.group({
        senha : ['',[Validators.required,Validators.minLength(6)]],
        confSenha : ['',[Validators.required,Validators.minLength(6)]]
      })
  }

  public validate(nvl){
    if(nvl !== 'Moderador'){
      if(nvl !== 'Admin'){
        this.router.navigate(['../'])
      }
    }
  }

  public recuperarRazaoSocial(): void {

    this.razaoSocial = undefined
    console.log(this.formEmpresa.value.codEmpresa)
    this.bd.consultaEmpresaCodigo(this.formEmpresa.value.codEmpresa)
      .then((dados: string) =>{
        this.dados = dados;
      });
    firebase.database().ref(`empresas/${this.formEmpresa.value.codEmpresa}`)
        .once('value')
        .then((snapshot: any) => {
        this.razaoSocial = snapshot.val().razaoSocial;
        });
  }


   public listarEmpresas(): void{
    this.bd.consultaEmpresas()
      .then((empresas: string) => {
        this.empresas = empresas

        //console.log(empresas)

    })

  }

  public cdtCliente(): void {
    let usuario : Usuario = new Usuario(
      this.formInitial.value.email,
      this.formPass.value.senha,
      this.formEmpresa.value.codEmpresa,
      this.nivel,
      this.formInitial.value.nome
    )
    this.autenticacao.cadastrarUsuario(usuario)
  }

}

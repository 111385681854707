import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Bd } from '../../config/bd.service';
import { Empresa } from '../../model/empresa.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Sublocacao } from '../../model/sublocacao.model';

@Component({
  selector: 'app-edit-empresa',
  templateUrl: './edit-empresa.component.html',
  styleUrls: ['./edit-empresa.component.css']
})
export class EditEmpresaComponent implements OnInit {

  constructor(
    private bd: Bd,
    public dialogRef: MatDialogRef<EditEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Empresa
  ) { }

  sublocacao: Sublocacao[];
  editControl: string;

  public formulario: FormGroup = new FormGroup({
    sublocal: new FormControl(null)
  });

  ngOnInit(): void {
    this.pesquisaSublocacao(this.data[0].codigoInterno);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public pesquisaSublocacao(codigoEmpresa: string): void{
    this.bd.buscaSublocacao(codigoEmpresa)
      .then((sublocacao: Sublocacao[]) => {
        this.sublocacao = sublocacao;
        console.log(sublocacao);
        this.formulario.value.sublocal = 'x';
      });
  }

  public adcionarSublocacao(): void{
    this.bd.adicionaSublocacao(this.data[0].codigoInterno, this.formulario.value.sublocal);
    this.pesquisaSublocacao(this.data[0].codigoInterno);
  }

  public excluirSublocacao(key: string): void {
    this.bd.excluirSublocacao(this.data[0].codigoInterno, key);
    this.pesquisaSublocacao(this.data[0].codigoInterno);
  }

  public editarSublocacao(editSub: string, codigo: string): void{
    this.bd.editarSublocacao(this.data[0].codigoInterno, codigo, editSub);
    this.pesquisaSublocacao(this.data[0].codigoInterno);
    this.editControl = '';
  }
}

<div class="margim bg-white">
  <div class="row">
    <div class="col-md seletor">
      <form [formGroup]="formulario">
        <div class="row">
          
          <h5>Selecione a empresa</h5>
          <!-- Formulário cliente -->

          <div class="input-group filtro" *ngIf="nivel === 'Cliente'">
            <select class="form-control cliente-select"
                    style="max-width: 250px;"
                    id="exampleFormControlSelect"
                    (change)="consultaEmpresaCodigo() || consultaDadosUnidade() || recuperarSublocacao()"
                    formControlName="codigoUnidade">
              <option>Unidade</option>
              <option *ngFor="let codigo of unidades" 
                      value="{{codigo.codigoInterno}}">{{codigo.codigoInterno}} - {{codigo.nomeFantasia}}</option>
            </select>
          </div>

          <!-- Formulário Moderador e Admin-->
          <div class="input-group filtro border-primary" *ngIf="nivel === 'Admin' || nivel === 'moderador'">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroup">Código:</label>
              </div>
              <input class="form-control cliente-select "
                      type="text"
                      placeholder="Default input"
                      (change)="consultaDadosUnidade() || recuperarSublocacao()"
                      formControlName="codigoUnidade" [textMask]="{mask: maskCodigo}">
          </div>

          <div class="custom-control custom-checkbox" *ngIf="filtroSub == true">
              <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="checkSublocal">
              <label class="custom-control-label" for="customCheck1">Filtrar por sublocal</label>
          </div>
        </div>
        <div class="row">
          <!-- Filtro por sublocação -->
          <div class="input-group filtro filtro-sub" *ngIf="formulario.get('checkSublocal').valid">
            <div class="input-group-prepend" >
              <label class="input-group-text" for="inputGroup">Filtrar por:</label>
            </div>
            <select formControlName="sublocacao" class="custom-select" id="inputGroup">          
              <option>Selecione uma unidade</option>
              <option *ngFor="let sub of sublocacao" >{{sub}}</option>
            </select>
          </div>
          
          <button type="button" class="btn btn-primary" data-toggle="button" (click)="consultaEmpresaCodigo()">
            Pesquisar
          </button>
        </div>
        
      </form>
    </div>
  
    <!-- Receber os dados da empresa -->
    <div class="col-sm seletor-a">
      <p *ngFor="let dados of unidadeData">{{dados.razaoSocial}}<br>
                                           {{dados.nomeFantasia}}<br>
                                           {{dados.endereco}}</p>
    </div>
  </div>

  
  <!--Gráfico de Inspeções-->

  <div class="row">
    <h6 class="topico">Inspeções:</h6>
  </div>

  <div class="row" align="center">
   <div class="col-md seletor">
    <div style="display: block">
      <canvas baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
      </canvas>
    </div>
   </div>
  </div>
  <div class="row">
    <div class="col-md seletor">
     <table class="table">
       <thead >
         <tr>
           <td align="center" colspan="9">
             <h6>Tabela de Inspeção</h6>
           </td>
         </tr>
         <tr>
             <th *ngFor="let data of barChartLabels">{{data}}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td *ngFor="let dado of data3">{{dado}}</td>
         </tr>
       </tbody>
     </table>
    </div>
  </div>

  <!--Gráfico de retiradas-->
  <div class="row"> 
    <h6 class="topico2">Retirada de equipamentos</h6>
  </div>
  <div class="row">
    <div class="col-md seletor">
      <div style="display: block">
        <canvas baseChart
              [datasets]="lineChartDataRetirada"
              [labels]="lineChartLabelsRetirada"
              [options]="lineChartOpRetirada"
              [legend]="lineChartLegendRetirada"
              [chartType]="lineChartTypeRetirada"
              (chartHover)="chartHoveredRetirada($event)"
              (chartClick)="chartClickedRetirada($event)">
        </canvas>
      </div>
    </div>
    <div class="col-md seletor">
      <table class="table">
        <thead >
          <tr>
            <td align="center" colspan="9">
              <h6>Tabela de Retirada</h6>
            </td>
          </tr>
          <tr>
            <th *ngFor="let data of lineChartLabelsRetirada">{{data}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let dado of arrayRetirada">{{dado}}</td>
          </tr>
        </tbody>
      </table>    
    </div>
  </div>
</div>
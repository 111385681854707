import { Component, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Empresa } from '../../../model/empresa.model';
import { Bd } from '../../../config/bd.service';
import { Relatorio } from '../../../model/relatorios.model';
import DataSource from 'devextreme/data/data_source';


@Component({
  selector: 'app-rel-clientes',
  templateUrl: './rel-clientes.component.html',
  styleUrls: ['./rel-clientes.component.css']
})

export class RelClientesComponent implements OnInit {

  constructor(
    private bd: Bd
  ) {  }

  public item: string;
  public itensRelatorio: String;
  public nivel: string = localStorage.getItem('nivel');
  public codigoInterno: string = localStorage.getItem('codEmpresa');
  public dados: string;
  public data: Date = new Date();
  public email: string = localStorage.getItem('email');
  public codUnidade: Empresa[];
  public qtdExt: number;
  public itemSelects: string;
  public sublocacao: string;
  public listaSub: string;
  public item2: Relatorio[];
  public co22kg: number;
  public co24kg: number;
  public co26kg: number;
  public co210kg: number;
  public co225kg: number;
  public co245kg: number;
  public ap10l: number;
  public ap75l: number;
  public pqsBc2kg: number;
  public pqsBc4kg: number;
  public pqsBc6kg: number;
  public pqsBc8kg: number;
  public pqsBc12kg: number;
  public pqsBc20kg: number;
  public pqsBc30kg: number;
  public pqsBc50kg: number;
  public pqsBc100kg: number;

  //PQS ABC
  public pqsABC2kg: number;
  public pqsABC4kg: number;
  public pqsABC45kg: number;
  public pqsABC6kg: number;
  public pqsABC8kg: number;
  public pqsABC9kg: number;
  public pqsABC12kg: number;

  public em9l: number;
  public em10l: number;
  public em50l: number;

  //Classe K
  public classeD: number;

  public formulario: FormGroup = new FormGroup({
    codigoUnidade: new FormControl(null),
    mesConsulta: new FormControl(null),
    anoConsulta: new FormControl(null),
    checkSub: new FormControl(null, [Validators.requiredTrue]),
    sublocacao: new FormControl(null)
  });

selectBoxDataSource = new DataSource({
  // store: this.codUnidade,
  map: (item) => ({
    key: item.type,
    items: item.collection
  })
});

  ngOnInit(): void {
    this.consultaUnidade();
    this.listarRelatorio();
  }

  public listarRelatorio(): any {

      this.bd.consultaCheklist(
        this.formulario.value.codigoUnidade,
        this.formulario.value.mesConsulta,
        this.formulario.value.anoConsulta
      )
  
      .then((itensRelatorio: String) => {
        this.itensRelatorio = itensRelatorio
        this.qtdExt = this.itensRelatorio.length
      })
  
      this.bd.consultaCheklist(
        this.formulario.value.codigoUnidade,
        this.formulario.value.mesConsulta,
        this.formulario.value.anoConsulta
      )
  
      .then((itensRelatorio: Relatorio[]) => {
        this.item2 = itensRelatorio
        this.contabilizarEquip()
      })

  }

  public consultaEmpresaCodigo(): void{
    this.recuperaSublocacao()
    this.bd.consultaEmpresaCodigo(this.formulario.value.codigoUnidade).then((dados: string) => {
      this.dados = dados
    }) 
  }

  public consultaUnidade(): void{
    this.bd.consultaUnidades(this.email)
      .then((codUnidade: Empresa[]) => {
        this.codUnidade = codUnidade        
      })
  }

  public contarEquips(): void{
    this.bd.consultaCheklistCount(this.formulario.value.codigoUnidade, 
                                  this.formulario.value.mesConsulta, 
                                  this.formulario.value.anoConsulta)
      .then((itemSelects: string) => {
        this.itemSelects = itemSelects
      })
  }

  public checarSublocal(): void{
    console.log(this.formulario.value.codigoUnidade)
    if(this.formulario.value.checkSub === true){
      this.bd.consultaChecklistaSub(this.formulario.value.codigoUnidade, 
                                    this.formulario.value.mesConsulta, 
                                    this.formulario.value.anoConsulta,
                                    this.formulario.value.sublocacao)
      .then((listaSub: string) => {
        this.itensRelatorio = listaSub
      this.bd.consultaChecklistaSub(this.formulario.value.codigoUnidade, 
                                    this.formulario.value.mesConsulta, 
                                    this.formulario.value.anoConsulta,
                                    this.formulario.value.sublocacao)
          .then((itensRelatorio: Relatorio[]) =>{
            this.item2 = itensRelatorio
            this.qtdExt = itensRelatorio.length
            
            this.contabilizarEquip()
          })
        console.log(listaSub)
      })
    }else{
      this.listarRelatorio()
    }
    
  }

  public recuperaSublocacao(){
    this.bd.buscaSublocacao(this.formulario.value.codigoUnidade)
      .then((sublocacao: string) => {
        this.sublocacao = sublocacao
        this.qtdExt = sublocacao.length
        console.log('recuperar: '+sublocacao)
      })

  }

  public contabilizarEquip(): void{
    this.co22kg  = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '2').length
    this.co24kg  = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '4').length
    this.co26kg  = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '6').length
    this.co210kg = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '10').length
    this.co225kg = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '25').length
    this.co245kg = this.item2.filter(x => x.tipo === 'CO2' && x.capacidade === '45').length

    this.ap10l = this.item2.filter(x => x.tipo === 'Água' && x.capacidade === '10').length
    this.ap75l = this.item2.filter(x => x.tipo === 'Água' && x.capacidade === '75').length

    this.pqsBc2kg   = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '2').length
    this.pqsBc4kg   = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '4').length
    this.pqsBc6kg   = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '6').length
    this.pqsBc8kg   = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '8').length
    this.pqsBc12kg  = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '12').length
    this.pqsBc20kg  = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '20').length
    this.pqsBc30kg  = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '30').length
    this.pqsBc50kg  = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '50').length
    this.pqsBc100kg = this.item2.filter(x => x.tipo === 'PQS BC' && x.capacidade === '100').length

    this.pqsABC2kg  = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '2').length;
    this.pqsABC4kg  = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '4').length;
    this.pqsABC45kg = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '4,5').length;
    this.pqsABC6kg  = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '6').length;
    this.pqsABC8kg  = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '8').length;
    this.pqsABC9kg  = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '9').length;
    this.pqsABC12kg = this.item2.filter(x => x.tipo === 'PQS ABC' && x.capacidade === '12').length;

    this.em9l  = this.item2.filter(x => x.tipo === 'Espuma' && x.capacidade === '9').length;
    this.em10l = this.item2.filter(x => x.tipo === 'Espuma' && x.capacidade === '10').length;
    this.em50l = this.item2.filter(x => x.tipo === 'Espuma' && x.capacidade === '50').length;

    this.classeD = this.item2.filter(x => x.tipo === 'Classe D').length
  }

  fullNameColumn_calculateCellValue (rowData) {
    return rowData.capacidade + " " + rowData.unidade;
  }

  onToolbarPreparing (e) {
   
    var toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function(item) {
        //console.log(item)
        if (item.name === "paging") {
            //console.log('paginando')
        }
    });
    // Adds a new item
    toolbarItems.push({
      location: "before",  
      widget: "dxButton", 
        options: { icon: "print", onClick: function (){ 
          this.paging = false
          setTimeout(function(){window.print()},500)
          //this.paging = true
        },
      },
    });

}

}

<div class="fluid-container">
    <mat-card class="card" >
        <mat-card-title>Dados do Usuario</mat-card-title>
        <mat-list>
            <mat-list-item> 
                <h5 class="empresa" >Empresa:</h5>
                <span class="example-spacer"></span>
                <h6>{{codEmpresa}}</h6>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item> 
                    <h5 class="nome"> Nome:</h5>
                    <span class="example-spacer"></span>
                    <h6>{{nome}}</h6>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item> 
                    <h5 class="email">E-mail:</h5>
                    <span class="example-spacer"></span>
                    <h6>{{email}}</h6>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="sa" >   
                    <h5 class="senha" >Senha:</h5>
                    <span class="example-spacer"></span>
                    <button  class="button" mat-raised-button (click)="resetSenha()">Resetar</button>
            </mat-list-item>     
        </mat-list>
    </mat-card>
</div>
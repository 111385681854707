import { Component, OnInit } from '@angular/core';
import { Bd } from '../../../config/bd.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Autenticacao } from '../../../config/autenticacao.service';
import { Empresa } from '../../../model/empresa.model';
import { EditUserComponent } from '../../../dialogs/edit-user/edit-user.component';
import { Usuario } from '../../../model/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-entidades',
  templateUrl: './entidades.component.html',
  styleUrls: ['./entidades.component.css']
})
export class EntidadesComponent implements OnInit {

  nome = localStorage.getItem('nome');
  sizescreen = window.screen.availHeight;
  usuarios: string;
  dados = false;
  email = 'nenhum';
  temorUsuario = '';
  infos: Usuario;
  unidades: string;
  unidades2: string;
  empresas: string;
  dadosUnidade: Empresa;
  nivel = localStorage.getItem('nivel');


  constructor(
    private bd: Bd,
    private autenticacao: Autenticacao,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  public formulario: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.email]),
    codEmpresa: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    termoUsuario: new FormControl(null),
    selectUser: new FormControl(null),
    termoEmpresa: new FormControl(null)
  });

  ngOnInit(): void {
    this.validate(this.nivel);
    this.atualizaListaUsuario(this.temorUsuario);
  }

  public validate(nivel): void {
    if (nivel !== 'Moderador') {
      if (nivel !== 'Admin') {
        this.router.navigate(['../']);
      }
    }
  }

  public atualizaListaUsuario(termoUsuario: string): void {
    if (this.formulario.value.termoUsuario === null) {
      this.bd.consultaUsuario()
        .then((usuarios: string) => {
          this.usuarios = usuarios;
        });
    } else {
      this.bd.consultaUsuarioPorTipo(
        this.formulario.value.selectUser,
        this.formulario.value.termoUsuario)
        .then((usuarios: string) => {
          this.usuarios = usuarios;
        });
    }
  }

  public listarUsuarios(): void {
    this.bd.consultaUsuario()
      .then((usuarios: string) => {
        this.usuarios = usuarios;
      });
  }

  public recuperarDados(): void {
    this.dados = true;
  }

  public cadastrarUnidade(email, dadosUnidade): void {
    this.autenticacao.adicionarUnidade(
      email, dadosUnidade
    );
    this.formulario.value.codEmpresa = '';
  }

  public recuperaUsuario(email): void {
    this.bd.consultaUsuarioEmail(email)
      .then((dados: Usuario) => {
        this.infos = dados;
        this.openDialog(this.infos);
      });
  }

  public resetSenha(email: string): void {
    this.autenticacao.resetSenha(email);
  }

  public consultaUnidade(email: string): void {
    this.bd.consultaUnidadesUser(email)
      .then((unidades: string) => {
        this.unidades = unidades;
      });
  }

  public consultaEmpresa(unidades: string): void {
    this.bd.consultaEmpresaCodigo(unidades)
      .then((dados: string) => {
        this.empresas = dados;
      });
  }

  public atualizaListaEmpresa(termoEmpresa: string): void {
    this.bd.consultaEmpresaFiltro('razaoSocial', this.formulario.value.termoEmpresa)
      .then((empresas: string) => {
        this.empresas = empresas;
      });
  }

  onToolbarPreparing(e): void {

    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'print', onClick(): void {
          this.paging = false;
          setTimeout(() => { window.print(); }, 500);
        },
      },
    });
  }

  openDialog(infos: Usuario): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '550px',
      data: infos
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

}

import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { Empresa } from '../model/empresa.model';
import { Progresso } from './progresso.service';
import { Sublocacao } from '../model/sublocacao.model';

@Injectable()
export class Bd {

    public tokenEmail: string;

    constructor(private progresso: Progresso) { }

    public consultaEmpresas(): Promise<any> {

        return new Promise((resolve, reject) => {
            firebase.database().ref(`empresas/`)
                .once('value')
                .then((snapshot: any) => {
                    const empresas: Array<any> = [];
                    snapshot.forEach((childSnapshot: any) => {
                        const empresa = childSnapshot.val();
                        empresas.push(empresa);
                    });
                    resolve(empresas);
                });
        });
    }

    public consultaEmpresaCodigo(codigoInterno: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`empresas/`).orderByChild('codigoInterno').equalTo(codigoInterno)
                .once('value')
                .then((snapshot: any) => {
                    const dados = [];
                    snapshot.forEach((childSnapshot: any): void => {
                        const dado = childSnapshot.val();
                        dados.push(dado);
                    });
                    resolve(dados);
                });
        });
    }

    public consultaEmpresaFiltro(filtroEmpresa: string, termoEmpresa: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`empresas/`).orderByChild(filtroEmpresa).startAt(termoEmpresa)
                .once('value')
                .then((snapshot: any) => {
                    const dados: Array<any> = [];
                    snapshot.forEach((childSnapshot: any) => {
                        const dado = childSnapshot.val();
                        dados.push(dado);
                    });
                    resolve(dados);
                });
        });
    }

    // Consulta para Usuários
    public consultaUsuario(): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`usuarios/`)
                .once('value')
                .then((snapshot: any) => {
                    const usuarios: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const usuario = childSnapshot.val();

                        usuarios.push(usuario);
                    });

                    resolve(usuarios);
                });

        });
    }

    public consultaUsuarioEmail(email: string): Promise<any> {

        return new Promise((resolve, reject) => {
            firebase.database().ref(`usuarios/`).orderByChild('email').equalTo(email)
                .once('value')
                .then((snapshot: any) => {
                    const dados: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const dado = childSnapshot.val();

                        dados.push(dado);
                    });

                    resolve(dados);
                });

        });
    }

    public consultaUsuarioPorTipo(filtro: string, termo: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`usuarios/`).orderByChild(filtro).limitToFirst(4).startAt(termo)
                .once('value')
                .then((snapshot: any) => {
                    const usuarios: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const usuario = childSnapshot.val();
                        usuarios.push(usuario);
                        // console.log(usuarios)
                    });

                    resolve(usuarios);
                    // console.log(usuarios)
                });

        });
    }

    // Consulta para Checklist em extintores
    public consultaCheklist(codigoInterno: string, mesConsulta: string, anoConsulta: string): Promise<any> {
        // console.log(codigoInterno)
        // console.log(mesConsulta)
        // console.log(anoConsulta)

        return new Promise((resolve, reject) => {

            firebase.database().ref(`checklist/${codigoInterno}/${mesConsulta + anoConsulta}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {
                    const itensRelatorio: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const item = childSnapshot.val();

                        itensRelatorio.push(item);

                        // console.log(item)
                    });

                    resolve(itensRelatorio);
                });
        });
    }

    public consultaCheklistCount(codigoInterno: string, mesConsulta: string, anoConsulta: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`checklist/${codigoInterno}/${mesConsulta + anoConsulta}`)
                .orderByChild('tipo')
                .equalTo('CO2')
                .once('value')
                .then((snapshot: any) => {
                    const itemSelects: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const itemSelect = childSnapshot.val();

                        itemSelects.push(itemSelect);

                    });
                    resolve(itemSelects);
                });
        });
    }

    public consultaChecklistaSub(codigoInterno: string, mes: string, ano: string, sublocal: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`checklist/${codigoInterno}/${mes + ano}`)
                .orderByChild('subLocacao')
                .equalTo(sublocal)
                .once('value')
                .then((snapshot: any) => {
                    const itemSubs: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const itemSub = childSnapshot.val();

                        itemSubs.push(itemSub);
                    });
                    resolve(itemSubs);
                });
        });
    }


    public consultaNívelUsuario(): any {
        this.tokenEmail = localStorage.getItem('email');
        firebase.database().ref(`usuarios/${btoa(this.tokenEmail)}`)
            .once('value')
            .then((snapshot: any) => {
                localStorage.setItem('nome', snapshot.val().nome);
                localStorage.setItem('nivel', snapshot.val().nivel);
                localStorage.setItem('codEmpresa', snapshot.val().codEmpresa);
            });
    }

    public publicar(publicacao: any): void {

        console.log(publicacao);
        console.log(publicacao.codigoEmpresa);

        firebase.database().ref(`relatorios/${btoa(publicacao.codigoEmpresa)}/${publicacao.tipoRelatorio}/${publicacao.ano}/${publicacao.mes}`)
            .push({
                unidade: publicacao.unidade,
                tipoRelatorio: publicacao.tipoRelatorio,
                mes: publicacao.mes,
                ano: publicacao.ano,
                createdAt: Date.now(),
            })
            .then((resposta: any): void => {
                let nomeImagem = resposta.key;
                try {
                    firebase.storage().ref()
                        .child(`relatorios/${nomeImagem}`)
                        .put(publicacao.relatorioPDF)
                        .on(firebase.storage.TaskEvent.STATE_CHANGED,
                            // acompanhamento do processo de upload
                            (snapshot: any) => {
                                this.progresso.status = 'andamento';
                                this.progresso.estado = snapshot;
                                // console.log('Snapshot on()',snapshot)
                            },
                            (error) => {
                                this.progresso.status = 'erro';
                                console.log(error);
                            },
                            () => {
                                this.progresso.status = 'concluido';
                                // finalizacao do processo de upload
                                console.log(`Upload completo arquivo ${nomeImagem}`);
                            }
                        );
                } catch (error) {
                    console.log(error)
                }
            });
    }

    public consultaArquivo(
        tipo: string,
        tipoRelatorio: string,
        mes: string,
        ano: string)
        : Promise<any> {

        return new Promise((resolve, reject) => {
            console.log(tipo)
            firebase.database().ref(`relatorios/${btoa(tipo)}/${tipoRelatorio}/${ano}/${mes}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {
                    console.log(snapshot.val());

                    const arquivos: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {
                        const arquivo = childSnapshot.val();
                        console.log(childSnapshot.key)
                        // consultar a url da imagem
                        firebase.storage().ref()
                            .child(`relatorios/${childSnapshot.key}`)
                            .getDownloadURL()
                            .then((url: string) => {
                                arquivo.url_imagem = url;
                                // consultar o titulo da publicacao
                                firebase.database().ref(`relatorios/${btoa(tipo)}/${tipoRelatorio}/${ano}/${mes}/${childSnapshot.key}`)
                                    .once('value')
                                    .then((snapshot: any) => {
                                        arquivo.titulo = snapshot.val();
                                        arquivo.key = childSnapshot.key;
                                        console.log(childSnapshot.key)
                                        arquivos.push(arquivo);
                                    });
                            });
                    });
                    resolve(arquivos);
                });


        });

    }

    public deletaArquivo(codEmpresa, tipo, ano, mes, key) {
        firebase.database().ref(`relatorios/${btoa(codEmpresa)}/${tipo}/${ano}/${mes}/${key}`).set(null);
        firebase.storage().ref(`relatorios/${key}`).delete();
    }


    public consultaExtintores(codEmpresa: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`extintor/${codEmpresa}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {

                    const extintores: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const extintor = childSnapshot.val();

                        extintores.push(extintor);
                        // console.log(extintor)
                    });

                    resolve(extintores);

                });
        });
    }

    public consultaExtintoreSublocacao(codigoEmpresa: string, sublocal: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`extintor/${codigoEmpresa}`).orderByChild('subLocacao')
                .equalTo(sublocal)
                .once('value')
                .then((snapshot: any) => {
                    const extintores: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const extintor = childSnapshot.val();

                        extintores.push(extintor);

                        function compare(a, b) {
                            if (a.numeroCliente < b.numeroCliente) {
                                return -1;
                            } if (a.numeroCliente > b.numeroCliente) {
                                return 1;
                            }
                            return 0;
                        }

                        console.log(extintores.sort(compare));

                    });

                    resolve(extintores);
                });
        });
    }

    public consultaExtintoresTipo(codEmpresa: string, tipo: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`extintor/${codEmpresa}`).orderByChild('tipo').equalTo(tipo)
                .once('value')
                .then((snapshot: any) => {

                    const extintores: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const extintor = childSnapshot.val();

                        extintores.push(extintor);
                        // console.log(extintor)
                    });

                    resolve(extintores);

                });
        });
    }

    public consultaHidrantes(codEmpresa: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`hidrante/${codEmpresa}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {

                    const hidrantes: Array<any> = [];

                    // console.log(snapshot.val())
                    snapshot.forEach((childSnapshot: any) => {

                        const hidrante = childSnapshot.val();
                        const codigoHidrante = childSnapshot.val().codigoProtecin;

                        hidrantes.push(hidrante);
                    });
                    resolve(hidrantes);
                })

        });

    }

    public consultaCheckHidrante(codEmpresa: string, mes: string, ano: string): Promise<any> {
        return new Promise((resolve, reject) => {

            firebase.database().ref(`checkListHidrante/${codEmpresa}/${mes + ano}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {

                    const checkListHidrante: Array<any> = [];

                    // console.log(snapshot.val())
                    snapshot.forEach((childSnapshot: any) => {
                        const checkHidrante = childSnapshot.val();

                        checkListHidrante.push(checkHidrante);
                    });
                    resolve(checkListHidrante);
                });
        });


    }


    public consultaUnidades(email: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`usuarios/${btoa(email)}/unidade`)
                .once('value')
                .then((snapshot: any) => {
                    const codUnidade: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const dadoUnidade = childSnapshot.val();

                        codUnidade.push(dadoUnidade);

                        // console.log("BD Consulta Unida" + dadoUnidade)
                    });

                    resolve(codUnidade);
                });

        });


    }


    public consultaHidrantes1(codEmpresa: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`hidrante/${codEmpresa}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {

                    const hidrantes: Array<any> = [];

                    // console.log(snapshot.val())
                    snapshot.forEach((childSnapshot: any) => {

                        const hidrante = childSnapshot.val();
                        const codigoHidrante = childSnapshot.val().codigoProtecin;

                        firebase.database().ref(`Mangueira/${codEmpresa}/${codigoHidrante}`)
                            .once('value')
                            .then((snapshot: any) => {

                                snapshot.forEach((childSnapshot: any) => {
                                    // console.log(childSnapshot.val())

                                    hidrante.mangueira = childSnapshot.val();
                                    hidrante.tam_mangueira = childSnapshot.val().tamanho;


                                    hidrantes.push(hidrante.mangueira);
                                });

                            });

                    });

                    resolve(hidrantes);
                })

        });

    }



    public consultarMangueiras(codEmpresa: String, codigoHidrante: String): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`Mangueira/${codEmpresa}/${codigoHidrante}`).orderByChild('numeroCliente')
                .once('value')
                .then((snapshot: any) => {

                    const mangueiras: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const mangueira = childSnapshot.val();

                        mangueiras.push(mangueira);
                    });

                    resolve(mangueiras);
                });

        });

    }

    public adicionaCronogram(email: string, empresas: Empresa): void {

        firebase.database().ref(`cronograma/${btoa(email)}/${empresas.codigoInterno}`)
            .set(empresas);
        // console.log(cronograma)

    }

    public recuperarCronograma(email: string): Promise<any> {

        return new Promise((resolve, reject) => {

            firebase.database().ref(`cronograma/${btoa(email)}`)
                .once('value')
                .then((snapshot: any) => {

                    const cronogramas: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const cronograma = childSnapshot.val();

                        cronogramas.push(cronograma);
                    });

                    resolve(cronogramas);

                });
        });
    }


    public removerCronograma(email: string, codigoEmpresa: string) {

        firebase.database().ref(`cronograma/${btoa(email)}/${codigoEmpresa}`)
            .remove().then(() => alert('empresa excluida'));

    }

    public consultaUnidadesUser(email: string): Promise<any> {
        return new Promise((resolve, reject) => {

            firebase.database().ref(`usuarios/${btoa(email)}/unidade`)
                .once('value')
                .then((snapshot: any) => {
                    const unidades: Array<any> = [];
                    snapshot.forEach((childsnapshot: any) => {
                        const unidade = childsnapshot.val();
                        unidades.push(unidade);
                    });
                    resolve(unidades);
                });
        });
    }

    public buscaTipoRelatorio(codigoEmpresa: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`relatorios/${btoa(codigoEmpresa)}/`)
                .once('value')
                .then((snapshot: any) => {
                    const tipos: Array<any> = [];
                    snapshot.forEach((childsnapshot: any) => {
                        const tipo = childsnapshot.key;
                        tipos.push(tipo);
                    });
                    resolve(tipos);
                });
        });
    }

    public buscaAnoRelatorio(codigoEmpresa: string, tipoRelatorio: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`relatorios/${btoa(codigoEmpresa)}/${tipoRelatorio}`)
                .once('value')
                .then((snapshot: any) => {
                    const anos: Array<any> = [];
                    snapshot.forEach((childsnapshot: any) => {
                        const ano = childsnapshot.key;

                        anos.push(ano);
                    });
                    resolve(anos);
                });
        });
    }

    public buscaMesRelatorio(codigoEmpresa: string,
        tipoRelatorio: string,
        anoRelatorio: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`relatorios/${btoa(codigoEmpresa)}/${tipoRelatorio}/${anoRelatorio}`)
                .once('value')
                .then((snapshot: any) => {
                    const meses: Array<any> = [];
                    snapshot.forEach((childsnapshot: any) => {

                        const mes = childsnapshot.key;

                        meses.push(mes);
                    });
                    resolve(meses);
                });
        });
    }

    public buscarRetiradaExtintor(codigoEmpresa: string, mesConsulta: string, anoConsulta: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`retiradaExtintores/${codigoEmpresa}/${mesConsulta + anoConsulta}`)
                .once('value')
                .then((snapshot: any) => {
                    const retiradas: Array<any> = [];

                    snapshot.forEach((childSnapshot: any) => {

                        const retirada = childSnapshot.val();

                        retiradas.push(retirada);
                    });
                    resolve(retiradas);
                });
        });
    }

    public buscaSublocacao(codigoEmpresa: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`empresas/${codigoEmpresa}/unidades`)
                .once('value')
                .then((snapshot: any) => {
                    const subLocals: Sublocacao[] = [];

                    snapshot.forEach((childSnapshot: any) => {
                        console.log(childSnapshot.key);
                        const subLocal = new Sublocacao(
                            childSnapshot.key,
                            childSnapshot.val()
                        );

                        const subLocal1 = childSnapshot.val();
                        console.log(subLocal1.key);
                        subLocals.push(subLocal);
                    });
                    resolve(subLocals);
                });
        });

    }

    public adicionaSublocacao(codigoEmpresa: string, sublocal: string): void {
        firebase.database().ref(`empresas/${codigoEmpresa}/unidades`)
            .push().set(sublocal);
    }

    public excluirSublocacao(codigoEmpresa: string, key: string): void {
        firebase.database().ref(`empresas/${codigoEmpresa}/unidades/${key}`).remove();
    }

    public editarSublocacao(codigoEmpresa: string, key: string, novaDescricao: string) {
        firebase.database().ref(`empresas/${codigoEmpresa}/unidades/${key}`).set(novaDescricao);
    }

    public excluirUnidadeUser(email: string, codigoUnidade: string): void {
        firebase.database().ref(`usuarios/${btoa(email)}/unidade/${codigoUnidade}`).remove();
    }




}

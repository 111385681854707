
import {interval as observableInterval,  Observable ,  Subject } from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Autenticacao } from '../config/autenticacao.service';
import { Router } from '@angular/router'
import 'rxjs/Rx'



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  public opcao = ''
  public transicaoTemplate: string = ''
  public nomeUsuario: string
  public tipoUsuario: string
  public uidFirebase: string
  step = 0;

  constructor(
    private autenticacao: Autenticacao,
    private router: Router
  ) { }

  ngOnInit() {
    this.recuperarNomeUser()

  }
  
  public sair(): void {
    this.autenticacao.sair()
  }


  public recuperarNomeUser(): void {

    let acompanhamentoDados = observableInterval(500)

    let continua = new Subject

    continua.next(true)

    acompanhamentoDados.pipe(
      takeUntil(continua))
      .subscribe(()=> {

        this.nomeUsuario = localStorage.getItem('nome')
        this.tipoUsuario = localStorage.getItem('nivel')
        this.uidFirebase = localStorage.getItem('idToken')

        if(this.uidFirebase === null){
          this.router.navigate(['../'])
        }
        //console.log('dentro da função:', this.tipoUsuario)
        if(this.tipoUsuario !== null ) {
          continua.next(false)
        }
      })
    }
}


  
      
    

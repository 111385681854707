<div class="margem">

  <!-- Cabeçalho-->
  <table class="table table1">
      <thead class="thead-primary">
        <tr>
            <th scope="col">
                <img class="logo d-flex align-items-center" src="../../../assets/logo-protecin.png"/>  
            </th>
            <th scope="col"></th>
            <th scope="col" *ngFor="let dado of dados">
              <small><b>{{dado.codigoInterno}} {{dado.razaoSocial}}</b></small><br/>
              <small>{{dado.endereco}} - {{dado.cidade}}</small>
            </th>
        </tr>
      </thead>
      <tbody>
        <tr>
  
        </tr>
      </tbody>
  </table>
  <!--Fim Cabeçalho-->

  <!--Planilha-->
  <div id="grid">
      <form [formGroup]="formulario">
        <input
          id="select-prefix"
          type="text"
          class="form-control" 
          (change)="consultaEmpresaCodigo() || recuperaSublocacao()" 
          placeholder="Digite o código da unidade"
          formControlName="codigoUnidade" 
          *ngIf="nivel === 'Admin' || nivel === 'Moderador' "/>

        <select 
          id="select-prefix"
          class="form-control" 
          (change)="consultaEmpresaCodigo() || recuperaSublocacao()" 
          placeholder="Selecione a unidade"
          formControlName="codigoUnidade" 
          *ngIf=" nivel === 'Cliente' ">
          <option value="" disabled selected>Selecione uma unidade...</option>
          <option *ngFor="let codigo of codUnidade" value="{{codigo.codigoInterno}}" >{{codigo.codigoInterno}} - {{codigo.nomeFantasia}}</option>
        </select>
  
        <dx-check-box
          formControlName="checkSub"
          [value]="true"
          [width]="110"
          text="Por sublocação?"></dx-check-box>

        <dx-select-box 
          *ngIf="formulario.get('checkSub').valid"
          id="select-prefix"
          formControlName="codigoSublocacao"
          [dataSource]="subLocal"
          placeholder= "Selecione a Sublocação"
          [value]="prefix">
        </dx-select-box>

        <dx-check-box
          formControlName="checkVenc"
          [value]="true"
          [width]="110"
          text="Por vencimento?"></dx-check-box>

        <dx-select-box 
          *ngIf="formulario.get('checkVenc').valid"
          id="select-prefix"
          formControlName="mesVencimento"
          [dataSource]="meses"
          placeholder= "Selecione o mês"
          [value]="prefix">
        </dx-select-box>

        <dx-select-box 
          *ngIf="formulario.get('checkVenc').valid"
          id="select-prefix"
          formControlName="anoVencimento"
          [dataSource]="anos"
          placeholder= "Selecione o Ano"
          [value]="prefix">
        </dx-select-box>

        <dx-button
        text="Consultar"
        [disabled]="false"
        (onClick)="consultaEmpresaCodigo()">
        </dx-button>
      </form>
    
      <dx-data-grid
        #grid
        (onToolbarPreparing)="onToolbarPreparing($event)"
        id="gridContainer"
        [dataSource]="listExtintores"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        [showBorders]="true">
        
        <dxo-paging name="paging" [pageSize]="10" [enabled]="false"></dxo-paging>
  
        <dxo-pager
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[10, 20, 50]"
            [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-export [enabled]="true"></dxo-export>
  
            <dxi-column dataField="numeroCliente" dataType="number" width="60" [fixed]="true" caption="Nº"></dxi-column>
            <dxi-column dataField="codigoProtecin" dataType="text" caption="QR" ></dxi-column>
            <dxi-column dataField="numeroFabricante" dataType="text" caption="Nº Fab." ></dxi-column>
            <dxi-column dataField="localizacao" ></dxi-column>
            <dxi-column dataField="subLocacao" caption="Sublocação"></dxi-column>
            <dxi-column dataField="tipo"></dxi-column>
            <dxi-column dataField="capacidade" [calculateCellValue]="fullNameColumn_calculateCellValue"></dxi-column>
            <dxi-column dataField="seloInMetro" [visible]="false"></dxi-column>
            <dxi-column dataField="dataProximoTeste" caption="Próx. Teste"></dxi-column>
            <dxi-column dataField="dataProximaRecarga" caption="Próx. Recarga" ></dxi-column>
            <dxi-column dataField="dataUltimaInspecao" dataType="text"></dxi-column>
            <dxo-summary>
              <dxi-total-item
                column="numeroCliente"
                summaryType="count"
                displayFormat="Total: {0}">
              </dxi-total-item>
            </dxo-summary>
  
          <div *dxTemplate="let d of 'conformTemp'">
            <div *ngIf="d.value == 'Conforme'" class="conf">{{d.value}}</div>
            <div *ngIf="d.value == 'Não Conforme'" class="nconf">{{d.value}}</div>
            <div *ngIf="d.value == 'N/A'" class="naplic">{{d.value}}</div>
          </div>
    </dx-data-grid>   
  
  </div>
  <!--Fim da Planilha-->
</div>


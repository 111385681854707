
import {interval as observableInterval,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Bd } from '../../../config/bd.service';
import { Usuario } from '../../../model/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cronograma',
  templateUrl: './cronograma.stepe.html',
  styleUrls: ['./cronograma.stepe.css']
})
export class CronogramaComponent implements OnInit {

  usuarios: string;
  email: any;
  empresas: string;
  codEmpresa: string;
  cronogramas: string;
  dados: string;
  termoEmpresa = '';
  nivel = localStorage.getItem('nivel');

  public formulario: FormGroup = new FormGroup({
    email: new FormControl(null, Validators.required),
    tpEmpresas: new FormControl(null),
    termo: new FormControl(null),
    selectEmpresa: new FormControl(null, Validators.required),
    selectUsuario: new FormControl(null, Validators.required),
    termoEmpresa: new FormControl(null, Validators.required),
  });

  constructor(
    private bd: Bd,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.validate(this.nivel);
    this.atualizaListaEmpresa(this.termoEmpresa);
    const tempo = observableInterval(500);
    tempo.subscribe((intervalo: number) => {
      this.recuperarCronograma();
      this.buscarNome();
    });
  }

  public validate(nivel): void{
    if (nivel !== 'Moderador'){
      if (nivel !== 'Admin'){
        this.router.navigate(['../']);
      }
    }
  }

  public listarUsuarios(termo: string): void {
    this.bd.consultaUsuarioPorTipo(this.formulario.value.selectUsuario, termo)
      .then((usuarios: string) => {
        this.usuarios = usuarios;
      });
  }

  public atualizaListaEmpresa(termoEmpresa: string): void {
    if (this.formulario.value.termoEmpresa === null){
      this.bd.consultaEmpresas()
        .then((empresas: string) => {
          this.empresas = empresas;
        });
    }else{
      this.bd.consultaEmpresaFiltro(this.formulario.value.selectEmpresa, termoEmpresa)
          .then((empresas: string) => {
            this.empresas = empresas;
          });
    }
  }

  public criaCronograma(empresas): void{
  if (this.formulario.value.termo === null){
     alert('favor selecionar o operdaor');
  }else{
    this.bd.adicionaCronogram(
      this.formulario.value.termo,
      empresas);
    }
  }

  public recuperarCronograma(): void {
    this.bd.recuperarCronograma(this.formulario.value.termo)
      .then((cronogramas: string) => {
        this.cronogramas = cronogramas;
      });
  }

  public removerCronograma(codigoEmpresa): void {
    this.bd.removerCronograma(this.formulario.value.termo, codigoEmpresa);
  }

  public buscarNome(): void{
    this.bd.consultaUsuarioEmail(this.formulario.value.termo)
      .then((dados: string) => {
        this.dados = dados;
      });
  }

}

<div class="back" flex="50">

  <!--Navbar-->
  <div class="nav justify-content-end">
      <img class="logo" src="../../../assets/logo-proteci-neg.png" alt="">
  </div>


  <!--Dialog para recuperar a senha!-->
  <div layout="row"  class="caixa-login" *ngIf="esqueceuSenha;" >
      <form [formGroup]="formulario" (ngSubmit)="recuperarSenha()">
          <mat-card >
              <mat-card-header>
                  <mat-card-title>
                      <img src="../../../assets/logo-protecin.png" alt="">
                  </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <h6>Esqueceu a senha?</h6>
                  <mat-form-field appearance="outline">
                      <mat-label>E-mail</mat-label>
                      <input type="email" matInput formControlName="email" placeholder="Digite seu e-mail...">
                      <mat-icon matSuffix *ngIf="formulario.get('email').invalid">email</mat-icon>
                      <mat-icon matSuffix class="iconCheck" *ngIf="formulario.get('email').valid && formulario.get('email').touched">done</mat-icon>
                      <mat-hint *ngIf="formulario.get('email').valid && formulario.get('email').touched">OK</mat-hint>
                      <mat-hint *ngIf="formulario.get('email').invalid && formulario.get('email').touched">Digite o e-mail corretamente!</mat-hint>
                  </mat-form-field> 
              </mat-card-content>
              <mat-card-footer>
                  <button type="submit" mat-stroked-button color="primary">Recuperar Senha!</button> 
                  <button (click)="exibirLogin()" mat-button color="primary">Fazer o login</button>
              </mat-card-footer>
          </mat-card>     
      </form>
  </div>
  <!--// FIM Dialog para recuperar a senha!-->

  <!--Dialog para efetuar login-->
  <div layout="row"  class="caixa-login" layout-align="center" *ngIf="esqueceuSenha === false">
      <form [formGroup]="formulario" (ngSubmit)="autenticar()">
          <mat-card >
              <mat-card-header>
                  <mat-card-title>
                      <img src="../../../assets/logo-protecin.png" alt="">
                  </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                  <h6>Acesso ao usuário:</h6>
                  <mat-form-field appearance="outline">
                      <mat-label>E-mail</mat-label>
                      <input type="email" matInput formControlName="email" placeholder="Digite seu e-mail...">
                      <mat-icon matSuffix *ngIf="formulario.get('email').invalid">email</mat-icon>
                      <mat-icon matSuffix class="iconCheck" *ngIf="formulario.get('email').valid && formulario.get('email').touched">done</mat-icon>
                      <mat-hint *ngIf="formulario.get('email').valid && formulario.get('email').touched">OK</mat-hint>
                      <mat-hint *ngIf="formulario.get('email').invalid && formulario.get('email').touched">Digite o e-mail corretamente!</mat-hint>
                  </mat-form-field> <br><br>
                  <mat-form-field appearance="outline">
                      <mat-label>Senha</mat-label>
                      <input type="password" matInput formControlName="senha" placeholder="Digite sua senha...">
                      <mat-icon matSuffix *ngIf="formulario.get('senha').invalid">vpn_key</mat-icon>
                      <mat-icon matSuffix class="iconCheck" *ngIf="formulario.get('senha').valid && formulario.get('senha').touched">done</mat-icon>
                      <mat-hint *ngIf="formulario.get('senha').valid && formulario.get('senha').touched">OK</mat-hint>
                      <mat-hint *ngIf="formulario.get('senha').invalid && formulario.get('senha').touched">Digite a senha corretamente!</mat-hint>
                  </mat-form-field>
              </mat-card-content>
              <mat-card-footer>
                  <button mat-stroked-button color="primary">Acessar</button> 
                  <button (click)="exibirRecuperacaoSenha()" mat-button color="primary">Esqueceu a senha?</button>
              </mat-card-footer>
          </mat-card>     
      </form>
  </div>
  <!--// FIM Dialog para efetuar login-->
</div>
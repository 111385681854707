import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Bd } from '../../../config/bd.service'


@Component({
  selector: 'app-retirada-ext',
  templateUrl: './retirada-ext.component.html',
  styleUrls: ['./retirada-ext.component.css']
})
export class RetiradaExtComponent implements OnInit {

  public email: string = localStorage.getItem('email')
  public nivel: string = localStorage.getItem('nivel')
  public codUnidade: string
  public dados: string
  public retiradas: string
  public data: Date = new Date()
  public qtdExt: number

  constructor(
    private bd: Bd
  ) { }


  public formulario: FormGroup = new FormGroup({
    'codigoUnidade': new FormControl(null),
    'mesConsulta': new FormControl(null),
    'anoConsulta': new FormControl(null)
  })

  ngOnInit() {
    this.consultaUnidade()
  }

  public consultaUnidade(): void{
    this.bd.consultaUnidades(this.email)
      .then((codUnidade: string) =>{
        this.codUnidade = codUnidade
      })
  }

  public consultaEmpresaCodigo(): void{
    console.log("Mostrar dados empresa")
    this.bd.consultaEmpresaCodigo(this.formulario.value.codigoUnidade)
      .then((dados: string) => {
        this.dados = dados
        console.log(dados)
      })
  }

  public listarRelatorio(): any{
    this.bd.buscarRetiradaExtintor(this.formulario.value.codigoUnidade, this.formulario.value.mesConsulta, this.formulario.value.anoConsulta)
      .then((retiradas: string) => {
        this.retiradas = retiradas
        this.qtdExt = retiradas.length
        console.log(retiradas)
      })
  }

}


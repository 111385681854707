<div class="fluid-container">
    <mat-list role="list">
        <mat-list-item role="listitem">
            <h2>Carregar Arquivos</h2>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="example-container" [formGroup]="formCodEmp">
                <mat-form-field id="codEmp">
                    <input matInput placeholder="Codigo Empresa" formControlName="codEmpresa" type="text">
                </mat-form-field>
            </div>
        </mat-list-item>

        <mat-list-item role="listitem" class="select">
            <div [formGroup]="formSelect">
                <mat-form-field id="relatorios" class="col-md">
                    <mat-select formControlName="tipoRelatorio" placeholder="Tipo de Relatório">
                        <mat-option value="Inventário Extintores">Inventário Extintores</mat-option>
                        <mat-option value="Inventário Hidrantes">Inventário Hidrantes</mat-option>
                        <mat-option value="Inventário Abrigo de Mangueiras">Inventário Abrigo de Mangueiras</mat-option>
                        <mat-option value="Inventário Conjutos Autônomos">Inventário Conjutos Autônomos</mat-option>
                        <mat-option value="Inventário Conjuto Moto Bomba">Inventário Conjuto Moto Bomba</mat-option>
                        <mat-option value="Inventário Canhão Monitor">Inventário Canhão Monitor</mat-option>
                        <mat-option value="Inventário E.P.I">Inventário E.P.I</mat-option>
                        <mat-option value="Inventário Porta Corta Fogo">Inventário Porta Corta Fogo</mat-option>
                        <mat-option value="Checklist em Extintores">Checklist em Extintores</mat-option>
                        <mat-option value="Checklist em Hidrantes">Checklist em Hidrantes</mat-option>
                        <mat-option value="Checklist Abrigo de Mangueiras">Checklist Abrigo de Mangueiras</mat-option>
                        <mat-option value="Checklist Conjuntos Autônomos">Checklist Conjuntos Autônomos</mat-option>
                        <mat-option value="Checklist Conjunto Moto Bomba">Checklist Conjunto Moto Bomba</mat-option>
                        <mat-option value="Checklist Canhão Monitor">Checklist Canhão Monitor</mat-option>
                        <mat-option value="Checklist E.P.I.">Checklist E.P.I.</mat-option>
                        <mat-option value="Checklist Porta Corta Fogo">Checklist Porta Corta Fogo</mat-option>
                        <mat-option value="Checklist Não Conformidades">Checklist Não Conformidades</mat-option>
                        <mat-option value="Relatório de Serviço">Relatório de Serviço</mat-option>
                        <mat-option value="Ordem de Serviço">Ordem de Serviço</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field id="mes" class="col-md">
                    <mat-select formControlName="mes" placeholder="Mês">
                        <mat-option value="Janeiro">Janeiro</mat-option>
                        <mat-option value="Fevereiro">Fevereiro</mat-option>
                        <mat-option value="Março">Março</mat-option>
                        <mat-option value="Abril">Abril</mat-option>
                        <mat-option value="Maio">Maio</mat-option>
                        <mat-option value="Junho">Junho</mat-option>
                        <mat-option value="Julho">Julho</mat-option>
                        <mat-option value="Agosto">Agosto</mat-option>
                        <mat-option value="Setembro">Setembro</mat-option>
                        <mat-option value="Outubro">Outubro</mat-option>
                        <mat-option value="Novembro">Novembro</mat-option>
                        <mat-option value="Dezembro">Dezembro</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field id="ano" class="col-md">
                    <mat-select formControlName="ano" placeholder="Ano">
                        <mat-option value="2018">2018</mat-option>
                        <mat-option value="2019">2019</mat-option>
                        <mat-option value="2020">2020</mat-option>
                        <mat-option value="2021">2021</mat-option>
                        <mat-option value="2022">2022</mat-option>
                        <mat-option value="2023">2023</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="atualizarLista()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="example-container" [formGroup]="formUnidade">
                <mat-form-field id="unidade">
                    <input matInput placeholder="Unidade" formControlName="unidade">
                </mat-form-field>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem" [formGroup]="formUpload">
            <mat-form-field class="upload">
                <ngx-mat-file-input (change)="preparaPdfUpload($event)" formControlName="basicfile"
                    placeholder="Carregar Arquivo" [accept]="'.pdf'" formControlName="upload"></ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem" [formGroup]="formUpload">
            <button mat-raised-button class="button" (click)="publicar()">Carregar</button>
        </mat-list-item>
    </mat-list>
</div>
<mat-card class="card">
    <mat-list role="list">
        <mat-list-item>
            <h2>Consulta Relatórios</h2>
        </mat-list-item>
    </mat-list>
    <mat-list role="list-group" *ngFor="let arquivo of arquivos">
        <mat-list-item class="list-group-item list-item-action">
            <a href="{{arquivo.url_imagem}}" target="_blank">{{arquivo.tipoRelatorio}} {{arquivo.mes}} {{arquivo.ano}}
                {{arquivo.unidade}}</a>
            <span class="example-spacer"></span>
            <button class="float-right" mat-icon-button class="icon1" (click)="deleteArq(arquivo.key)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>
</mat-card>

<div class="col upload">
    <div class="col bg-warning " *ngIf="progressoPublicacao === 'andamento'">
        <h3>Publicação em andamento: {{porcentagemUpload}}%</h3>
        <div class="progress">
            <div class="progress-bar w-{{porcentagemUpload}}"></div>
        </div>
    </div>
</div>

<div class="col upload">
    <div class="col bg-success" *ngIf="progressoPublicacao === 'concluido'">
        <h3>Arquivo carregado com sucesso</h3>
        <div class="abc">
            <button class="btn btn-primary info" (click)="carregarOutroArquivo()">Carregar outro arquivo</button>
        </div>
    </div>
</div>
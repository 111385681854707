<!--Inicio Cabeçalho-->
<div class="cabecalho">
  <form [formGroup]="formulario">
    <div class="row">

      <div class="col-md caixa1">
        <h6>Procure pelo usuário desejado:<br> </h6>

        <mat-form-field>
          <mat-label>Filtrar por:</mat-label>
          <mat-select formControlName="selectUser">
            <mat-option value="nome">Nome</mat-option>
            <mat-option value="email">Email</mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="example-full-width">
          <input matInput placeholder="Consulta Usuários" #termoUsuario=""
            (keyup)="atualizaListaUsuario(termoUsuario.value)" formControlName="termoUsuario">
        </mat-form-field>

      </div>
    </div>
  </form>
</div>

<!--Fim Cabeçalho-->

<!--Planilha-->

<div class="sheetArea ">
  <dx-data-grid #grid (onToolbarPreparing)="onToolbarPreparing($event)" id="gridContainer" [dataSource]="usuarios"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [columnAutoWidth]="false"
    [showBorders]="true">

    <dxo-paging name="paging" [pageSize]="10" [enabled]="false"></dxo-paging>

    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50]" [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxi-column dataField="nome" dataType="text" [fixed]="true" caption="Nome"></dxi-column>
    <dxi-column dataField="email" dataType="text" caption="Email"></dxi-column>
    <dxi-column dataField="nivel" dataType="text" caption="Nível"></dxi-column>
    <dxi-column dataField="edit" caption="Editar" cellTemplate="cellTemplate">
      <div *dxTemplate="let data of 'cellTemplate'">
        <button class="btnicon" mat-icon-button color="primary" (click)="recuperaUsuario(data.values[1])">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="nome" summaryType="count" displayFormat="Total: {0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
<!--Fim da Planilha-->
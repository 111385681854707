import { Component, OnInit } from '@angular/core';
import { Bd } from '../../../config/bd.service';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import { FormGroup, FormControl } from '@angular/forms';
import { Empresa } from '../../../model/empresa.model';
import { MatDialog } from '@angular/material/dialog';
import { EditEmpresaComponent } from '../../../dialogs/edit-empresa/edit-empresa.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.stepe.html',
  styleUrls: ['./lista-empresas.stepe.css']
})

export class ListaEmpresasComponent implements OnInit {
  public email: string;
  public empresas: Empresa[];
  startAt = new Subject();
  endAt = new Subject();
  lastKeypress = 0;
  termoEmpresa = '';
  dadoEmpresa: Empresa;
  sublocacao: string;
  private nivel = localStorage.getItem('nivel');

  public formulario: FormGroup = new FormGroup({
    termoEmpresa: new FormControl(null),
    selecEmpresa: new FormControl(null)
  });

  public formSublocal: FormGroup = new FormGroup({
    sublocal: new FormControl(null)
  });

  constructor(
    private bd: Bd,
    public dialog: MatDialog,
    private router: Router,
   ) { }

  ngOnInit(): void {
    this.validate(this.nivel);
    firebase.auth().onAuthStateChanged((user) => {
      this.atualizaListaEmpresa(this.termoEmpresa);
    });
  }

  public validate(nivel): void{
    if (nivel !== 'Moderador'){
      if (nivel !== 'Admin'){
        this.router.navigate(['../']);
      }
    }
  }

  public atualizaListaEmpresa(termoEmpresa: string): void {
    if (this.formulario.value.termoEmpresa === null){
      this.bd.consultaEmpresas()
        .then((empresas: Empresa[]) => {
          this.empresas = empresas;
        });
      }else{
        this.bd.consultaEmpresaFiltro(this.formulario.value.selecEmpresa, termoEmpresa)
        .then((empresas: Empresa[]) => {
            this.empresas = empresas;
          }
        );
    }
  }

  public pesquisaEmpresa(codigoEmpresa: string): void{
    this.bd.consultaEmpresaCodigo(codigoEmpresa)
      .then((dadoEmpresa: Empresa) => {
          this.dadoEmpresa = dadoEmpresa;
          this.editEmpresa(codigoEmpresa, this.dadoEmpresa);
      });
    }

  public pesquisaSublocacao(codigoEmpresa: string): void{
    this.bd.buscaSublocacao(codigoEmpresa)
      .then((sublocacao: string) => {
        this.sublocacao = sublocacao;
        this.formSublocal.value.sublocal = 'x';
      });
  }

  public adicionaSublocacao(codigoEmpresa: string): void{
    this.bd.adicionaSublocacao(codigoEmpresa, this.formSublocal.value.sublocal);
    this.pesquisaSublocacao(codigoEmpresa);
  }

  onToolbarPreparing(e): void {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      location: 'before',
      widget: 'dxButton',
        options: { icon: 'print', onClick(): void{
          this.paging = false;
          setTimeout(() => { window.print(); }, 500);
        },
      },
    });
  }


  editEmpresa(codigoEmpresa: string, dadoEmpresa: Empresa): void{
    const dialogRef = this.dialog.open(EditEmpresaComponent, {
      width: '550px',
      data: dadoEmpresa
    });
  }
}

<h1 mat-dialog-title>Dados Usuários:</h1>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item> 
        <h5 class="empresa" >Empresa:</h5>
        <span class="example-spacer"></span>
        <h6>{{data[0].codEmpresa}}</h6>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> 
            <h5 class="nome" > Nome:</h5>
            <span class="example-spacer"></span>
            <h6>{{data[0].nome}}</h6>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> 
            <h5 class="email" >E-mail:</h5>
            <span class="example-spacer"></span>
            <h6>{{data[0].email}}</h6>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="sa" >   
            <h5 class="senha" >Senha:</h5>
            <span class="example-spacer"></span>
            <button  class="button" mat-raised-button (click)="resetSenha(data[0].email)" >Resetar</button>
    </mat-list-item>     
  </mat-list>

  <div class="row" style="margin-top: 30px; margin-bottom: 30px;">
    <div class="col">Unidades de acesso:</div> 
  </div>
  <div class="row" *ngFor="let unidade of unidades">
      <b>{{unidade.codigoInterno}} </b> {{unidade.nomeFantasia}} - {{unidade.cidade}}
      <span class="example-spacer"></span>
      <button mat-icon-button color="warn" (click)="deleteUnidade(data[0].email, unidade.codigoInterno)">
        <mat-icon class="deleteIcon">delete</mat-icon>
      </button>
  </div>
  
  <mat-form-field class="example-full-width">
      <input matInput
            aria-label="State" 
            [matAutocomplete]="auto" 
            #termo = ""
            placeholder="Razao Social Empresa...." 
            (keyup)="atualizaListaEmpresa(termo.value)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let empresa of empresas" [value]="empresa.codigoInterno" (click)="cadastrarUnidade(data[0].email ,empresa) || consultaUnidade(data[0].email)">
          <span>{{empresa.codigoInterno}}</span> |
          <span>{{empresa.razaoSocial}}</span> |
          <small>{{empresa.nomeFantasia}}</small>
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <span class="example-spacer"></span>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.email" cdkFocusInitial>Ok</button>
</div>


<div>
    <div class="row" >
        <mat-card class="card-cadastro" >
            <mat-card-title>Cadastrar Empresa</mat-card-title>
            <mat-vertical-stepper [linear]="isLinear" #stepper>
              <mat-step [stepControl]="formCodCliente">
                <form [formGroup]="formCodCliente">
                  <ng-template matStepLabel>Codigo Cliente</ng-template>
                  <mat-form-field>
                    <input matInput placeholder="Codigo Interno" formControlName="codInterno" maxlength="4" required>
                  </mat-form-field>
                  <div>
                    <button mat-button matStepperNext>Próximo</button>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="formInitial">
                <form [formGroup]="formInitial">
                  <ng-template matStepLabel>Dados Iniciais</ng-template>
                  <mat-form-field>
                    <input matInput placeholder="Razão Social" formControlName="razaoSocial" required>
                  </mat-form-field>
                  <div>
                    <mat-form-field>
                        <input matInput placeholder="Nome Fantasia" formControlName="nomeFantasia" required>
                    </mat-form-field>
                  </div>
                  <div>
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-button matStepperNext>Próximo</button>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="formDados" >
                  <form [formGroup]="formDados" >
                    <ng-template matStepLabel>Dados Complementares</ng-template>
                    <mat-form-field>
                            <input matInput placeholder="Endereço Completo" formControlName="endereco" required>
                    </mat-form-field>    
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Cidade" formControlName="cidade" required>
                        </mat-form-field>
                    </div>
                    <div>
                       <button mat-button (click)="efetuarCadastro()">Cadastrar</button>
                    </div>
                </form>
              </mat-step>
            </mat-vertical-stepper>
        </mat-card>

    </div>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Usuario } from '../../model/usuario.model';
import { Bd } from '../../config/bd.service';
import { Autenticacao } from '../../config/autenticacao.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  public unidades: string;
  public empresas: string;

  constructor(
    private bd: Bd,
    private autenticacao: Autenticacao,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario
  ) { }

  ngOnInit(): void {
    this.consultaUnidade(this.data[0].email);
    console.log('Dialog: ', this.data[0].email);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public consultaUnidade(email: string): void {
    this.bd.consultaUnidadesUser(email)
      .then((unidades: string) => {
        this.unidades = unidades;
        console.log(this.unidades);
      });
  }
  public atualizaListaEmpresa(termoEmpresa: string): void{
    this.bd.consultaEmpresaFiltro('razaoSocial', termoEmpresa)
      .then((empresas: string) => {
        this.empresas = empresas;
        console.log(this.empresas);
      });
  }

  public resetSenha(email: string): void{
    this.autenticacao.resetSenha(email);
  }

  public cadastrarUnidade(email: string, dadosUnidade): void{

    this.autenticacao.adicionarUnidade(
      email, dadosUnidade
    );
    console.log('Cadastrando a unidade', email,  dadosUnidade);
  }

  public deleteUnidade(email: string, codigoUnidade: string): void{
    this.bd.excluirUnidadeUser(email, codigoUnidade);
    this.consultaUnidade(email);
  }
}

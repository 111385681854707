import { Component, OnInit } from '@angular/core';
import {FirestoreService} from '../firestore.service'
@Component({
  selector: 'app-fbtransfer',
  templateUrl: './fbtransfer.component.html',
  styleUrls: ['./fbtransfer.component.css']
})
export class FbtransferComponent implements OnInit {

  constructor(
    private fireService: FirestoreService
  ) { }

  ngOnInit() {
    //this.fireService.addTeste('teste')
  }

  teste(){
    this.fireService.recoverUser()
  }

  teste2(){
    this.fireService.recoverEmpresas()
  }
  teste3(){
    this.fireService.recoverChecklist('0001')
  }


}
  
<div class="fluid-container">
    <mat-card class="card">
      <mat-card-subtitle>Bem-vindo, Sr(a). {{nome}}</mat-card-subtitle>
      <mat-card-title>Consulta Arquivos</mat-card-title>
      <mat-horizontal-stepper [linear]="isLinear" #stepper >
                <mat-step [stepControl]="formCodUnidade">
                  <form [formGroup]="formCodUnidade">
                    <ng-template matStepLabel>Codigo Unidade</ng-template>
                    <mat-form-field>
                      <mat-select  placeholder="Selecione uma Unidade" formControlName="unidades">
                          <mat-option (click)="buscarTipo()" *ngFor="let unidade of unidades" value="{{unidade.codigoInterno}}" > {{unidade.codigoInterno}} - {{unidade.nomeFantasia}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div>
                      <button mat-button matStepperNext >Proximo</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="formTipoRelatorio">
                  <form [formGroup]="formTipoRelatorio">
                    <ng-template matStepLabel  >Tipo de Relatorio</ng-template>
                    <mat-form-field>
                     <mat-select placeholder="Selecione o Tipo" formControlName="tipoRelatorio"   >
                         <mat-option (click)="buscarAno()" *ngFor="let tipo of tipos"  value="{{tipo}}" >{{tipo}}</mat-option>
                     </mat-select>
                    </mat-form-field>
                    <div>
                      <button mat-button matStepperPrevious>Voltar</button>
                      <button mat-button matStepperNext >Proximo</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="formAno">
                    <form [formGroup]="formAno">
                      <ng-template matStepLabel>Ano</ng-template>
                        <mat-form-field>
                            <mat-select placeholder="Selecione o Ano" formControlName="ano">
                                <mat-option  (click)="buscarMes()" *ngFor="let ano of anos" value="{{ano}}">{{ano}}</mat-option>
                            </mat-select>    
                        </mat-form-field>
                      <div>
                        <button mat-button matStepperPrevious>Voltar</button>
                        <button mat-button matStepperNext >Proximo</button>
                      </div>
                    </form>
                  </mat-step>
                <mat-step [stepControl]="formMes">
                  <ng-template matStepLabel>Mês</ng-template>
                  <form [formGroup]="formMes">
                    <mat-form-field>
                        <mat-select placeholder="Mês" formControlName="mes">
                            <mat-option  *ngFor="let mes of meses" value="{{mes}}" >{{mes}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                      <button mat-button type="submit" (click)="atualizarLista()" >Buscar</button>
                    </div>
                  </form>
                </mat-step>
              </mat-horizontal-stepper>
        </mat-card>
</div>
<div class="fluid-container" >
    <mat-card class="card1" >
        <mat-card-subtitle> <h4>{{anos}}</h4> </mat-card-subtitle>
        
        <mat-list role="list-group" class="list-group" *ngFor="let arquivo of arquivos" >
            <mat-list-item class="list-group-item list-item-action">
                <a href="{{arquivo.url_imagem}}" target="_blank">{{arquivo.tipoRelatorio}} {{arquivo.mes}} {{arquivo.ano}} {{arquivo.unidade}}</a>
            </mat-list-item>
        </mat-list>

    </mat-card>
</div>
import { Component, OnInit } from '@angular/core';
import { Bd } from '../../../config/bd.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-arquivo-cliente',
  templateUrl: './arquivo-cliente-stepe.html',
  styleUrls: ['./arquivo-cliente-stepe.css']
})
export class ArquivoClienteComponent implements OnInit {

  email: string = localStorage.getItem('email');
  nome: string = localStorage.getItem('nome');
  tipo: string = localStorage.getItem('codEmpresa');
  unidades: string;
  dados: string;
  arquivos: any;
  tipos: string;
  anos: string;
  meses: string;
  isLinear = true;
  formCodUnidade: FormGroup;
  formTipoRelatorio: FormGroup;
  formAno: FormGroup;
  formMes: FormGroup;

  public formulario: FormGroup = new FormGroup({
    unidades: new FormControl(null, [Validators.required]),
    tipoRelatorio : new FormControl(null, [Validators.required]),
    mes : new FormControl(null, [Validators.required]),
    ano : new FormControl(null, [Validators.required])
  });

  constructor(
    private bd: Bd,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.atualizarUnidades();
    this.formCodUnidade = this.formBuilder.group({
      unidades: ['', [Validators.required]]
    });
    this.formTipoRelatorio = this.formBuilder.group({
      tipoRelatorio : ['', Validators.required]
    });
    this.formAno = this.formBuilder.group({
      ano : ['', Validators.required]
    });
    this.formMes = this.formBuilder.group({
      mes : ['', Validators.required]
    });
  }


  public atualizarLista(): void{
    this.bd.consultaArquivo(
        this.formCodUnidade.value.unidades,
        this.formTipoRelatorio.value.tipoRelatorio,
        this.formMes.value.mes,
        this.formAno.value.ano)
      .then((arquivos: any) => {
        console.log(arquivos);
        this.arquivos = arquivos;
      });
  }

  public atualizarUnidades(): void {
    this.bd.consultaUnidades(this.email)
      .then((unidades: string) => {
        this.unidades = unidades;
      });
  }

  public consultaEmpresaCodigo(): void{
    this.bd.consultaEmpresaCodigo(this.formCodUnidade.value.unidades)
      .then((dados: string) => {
        this.dados = dados;
      });
  }

  public buscarTipo(): void{
    this.bd.buscaTipoRelatorio(this.formCodUnidade.value.unidades)
      .then((tipos: string) => {
        console.log(tipos);
        this.tipos = tipos;
      });
  }

  public buscarAno(): void{
    this.bd.buscaAnoRelatorio(this.formCodUnidade.value.unidades,
                              this.formTipoRelatorio.value.tipoRelatorio)
        .then((anos: string) => {
          this.anos = anos;
        });
  }

  public buscarMes(): void{
    this.bd.buscaMesRelatorio(this.formCodUnidade.value.unidades,
                              this.formTipoRelatorio.value.tipoRelatorio,
                              this.formAno.value.ano)
        .then((meses: string) => {
          this.meses = meses;
        });
  }
}

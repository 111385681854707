<mat-drawer-container class="example-container" autosize *ngIf="uidFirebase !== null">
        <mat-drawer #drawer class="example-sidenav" mode="over">
            <div class="row flexbox">
                <a [routerLink]="['/profile']" class="row flexbox" >
                  <mat-icon class="avatar">account_circle</mat-icon>
                  <span class="user-title ">{{nomeUsuario}}</span>
                </a>
            </div>
            <mat-accordion class="mat-accordion">
                    <mat-expansion-panel *ngIf=" tipoUsuario === 'Admin' || tipoUsuario === 'Moderador'">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div style="text-align:center">
                              <i class="fas fa-users icon-menu"></i>
                              Usuários
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <mat-list>
                            <a [routerLink]="['/cadastro']"><mat-list-item> Cadastro Usuário</mat-list-item></a>
                            <mat-divider></mat-divider>
                            <a [routerLink]="['/cadastro-cliente']"><mat-list-item>Cadastro Cliente</mat-list-item></a>
                            <mat-divider></mat-divider>
                            <a [routerLink]="['/entidades']"><mat-list-item>Lista Usuários</mat-list-item></a>
                            <mat-divider></mat-divider>
                            <a [routerLink]="['/cronograma']"><mat-list-item>Cronograma</mat-list-item></a>
                        </mat-list>
                    </mat-expansion-panel>
                  <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false" *ngIf=" tipoUsuario === 'Admin' || tipoUsuario === 'Moderador'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div style="text-align:center">
                              <i class="fas fa-briefcase icon-menu"></i>
                              Empresas
                          </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list>
                      <a [routerLink]="['/cadastro-empresa']"><mat-list-item>Cadastro Empresas</mat-list-item></a>
                      <mat-divider></mat-divider>
                      <a [routerLink]="['/lista-empresas']"><mat-list-item>lista Empresas</mat-list-item></a>
                    </mat-list>
                  </mat-expansion-panel>
                  <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div style="text-align:center">
                              <i class="fas fa-fire-extinguisher icon-menu"></i>
                              Extintores
                          </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list>
                      <a [routerLink]="['/gerencial-extintores']"><mat-list-item>Gerencial</mat-list-item></a>
                      <mat-divider></mat-divider>
                      <a [routerLink]="['/inventario-extintores']"><mat-list-item>Inventário</mat-list-item></a>
                      <mat-divider></mat-divider>
                      <a [routerLink]="['/checklist-extintores']"><mat-list-item>Checklist</mat-list-item></a>
                      <mat-divider></mat-divider>
                      <a [routerLink]="['/retirada-extintores']"><mat-list-item>Retirada</mat-list-item></a>
                    </mat-list>
                  </mat-expansion-panel>

                  <mat-expansion-panel (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div style="text-align:center">
                              <i class="fas fa-paste icon-menu"></i>
                            Documentos
                          </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list>
                      <a [routerLink]="['/arquivo']"><mat-list-item>Arquivo</mat-list-item></a>
                      <mat-divider></mat-divider>
                      <a [routerLink]="['/upload']" *ngIf=" tipoUsuario === 'Admin' || tipoUsuario === 'Moderador'"><mat-list-item>Upload</mat-list-item></a>
                    </mat-list>
                  </mat-expansion-panel>
            </mat-accordion>

            <div class="sair">
              <a (click)="sair()"><span class="logout" >Logout</span></a>
            </div>
    
         <!--  <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p> --> 
          <!--
          <button (click)="showFiller = !showFiller" mat-raised-button>
            Toggle extra text
          </button>
          -->
        </mat-drawer>
      
        <mat-toolbar class="toolbar">
            <mat-toolbar-row>
                <button mat-fab color="warn" (click)="drawer.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>
                <span class="example-spacer"></span>
                <a [routerLink]="['/home']">
                  <img _ngcontent-c10="" alt="" class="logo" src="../../assets/logo-proteci-neg.png">
                </a>
            </mat-toolbar-row>
        </mat-toolbar>
    
        <div class="example-sidenav-content">
            <router-outlet></router-outlet>
        </div>
      
      </mat-drawer-container>

      <app-login *ngIf="tipoUsuario === '' || tipoUsuario === null"></app-login>


<mat-drawer-container class="container">
    <mat-drawer mode="side" id="sidenav" opened>
        <form [formGroup]="formulario">
            <h6>Pesquisa Empresas</h6>
            <div>
                <mat-form-field>
                    <mat-label>Selecione um opção:</mat-label>
                    <mat-select formControlName="selectEmpresa">
                      <mat-option value="codigoInterno">Código</mat-option>
                      <mat-option value="razaoSocial">Razão Social</mat-option>
                      <mat-option value="nomeFantasia">Nome Fantasia</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput 
                           #termoEmpresa
                           placeholder="Consulte..." 
                           formControlName="termoEmpresa"
                           (keyup)="atualizaListaEmpresa(termoEmpresa.value)">
                </mat-form-field>
            </div>
            <div class="titulo-lista">
                <h6>Empresas disponiveis</h6>
              </div>
              <ul class="list-group">
                <li  class="media lista-config list-group-item-action list-group-item" (click)="criaCronograma(empresa)" *ngFor="let empresa of empresas">
                        <div class="media-body ">
                    <a target="_blank" class="" >  
                      <strong>{{ empresa.codigoInterno}}</strong> {{empresa.razaoSocial}} <br/>
                              {{empresa.nomeFantasia}} <br/> 
                              {{empresa.endereco}}  -  {{empresa.cidade}}
                    </a>
                  </div>
                </li>
              </ul>
        </form>
    </mat-drawer>
    <mat-drawer-content id="content">
        <div class="box">
            <form [formGroup]="formulario">
                <h6>Pesquisa Operador</h6>
                <div>
                    <mat-form-field>
                        <mat-label>Selecione um opção:</mat-label>
                        <mat-select formControlName="selectUsuario">
                            <mat-option value="nome">Nome</mat-option>
                            <mat-option value="email">Email</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput 
                                list="lista"
                                #termo = ""
                                placeholder="Consulte..." 
                                formControlName="termo"
                                (keyup)="listarUsuarios(termo.value)">
                    </mat-form-field>
                    <datalist class="datalist" id="lista"  (click)="recuperarCronograma(); buscarNome()">
                        <option *ngFor="let usuario of usuarios" value="{{usuario.email}}">{{usuario.nome}} - {{usuario.email}}</option>
                    </datalist>
                </div>
                <div>
                    <h6 *ngFor="let dado of dados">Lista de cronograma de: {{dado.nome}}</h6>
                </div>
                <!-- Lista de cronograma -->
                <ul class="list-group user-list">
                <li  class="media lista-config" *ngFor="let cronograma of cronogramas">
                    <div class="media-body">
                    <a target="_blank" >
                        <strong>{{cronograma.codigoInterno}}</strong> {{cronograma.razaoSocial}} <br/> 
                                {{cronograma.nomeFantasia}}
                                {{cronograma.endereco}}
                    </a>
                    </div>
                    <!-- Botão com função de remover cronograma -->
                    <img class="mr-3 icones" src="../../assets/error.png" alt="" (click)="removerCronograma(cronograma.codigoInterno)">
                </li>
                </ul>
            </form>
        </div>
    </mat-drawer-content>
  </mat-drawer-container>
import { Injectable} from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app'
import { Usuario } from '../model/usuario.model';
import { Empresa } from '../model/empresa.model';
import { Extintor } from '../model/extintor.model';


@Injectable()
export class FirestoreService{
    constructor(
        private firestore: AngularFirestore
    ){}

    public  data ={
        nome: 'felipe',
        descricao: 'teste'
    }

    recoverUser(){
        return new Promise((resolve, reject) => {
            firebase.database().ref(`usuarios/`)
                .once('value')
                .then((snapshot: any) => {
                    let usuarios: Array<Usuario>

                    snapshot.forEach((childSnapshot: any) => {
                        let nome = childSnapshot.val().nome
                        let email = childSnapshot.val().email
                        let nivel = childSnapshot.val().nivel
                        let codEmpresa = childSnapshot.val().codEmpresa
                        let senha = childSnapshot.val().senha
                        let usuario = new Usuario(
                            email,senha, codEmpresa , nivel, nome
                            )
                        console.log(usuario)
                        this.firestore.collection('usuarios').doc(btoa(email)).set({
                            nome: usuario.nome,
                            email: usuario.email,
                            senha: usuario.senha,
                            nivel: usuario.nivel,
                            codEmpresa: usuario.codigoEmpresa
                        })
                    })
                })
        })
    }

    recoverEmpresas(){
        firebase.database().ref(`empresas`)
            .once('value')
            .then((snapshot: any) => {
                let empresas: Array<Empresa>

                snapshot.forEach((childSnapshot: any) => {
                    let empresa =  new Empresa(
                        childSnapshot.val().codigoInterno,
                        childSnapshot.val().razaoSocial,
                        childSnapshot.val().nomeFantasia,
                        childSnapshot.val().endereco,
                        childSnapshot.val().cidade,
                        )
                    console.log(empresa)
                    this.recoverExtintores(empresa.codigoInterno)
                    this.firestore
                        .collection('empresas')
                        .doc(empresa.codigoInterno)
                        .set({
                            codigoInterno: empresa.codigoInterno,
                            razaoSocial: empresa.razaoSocial,
                            nomeFantasia: empresa.nomeFantasia,
                            endereco: empresa.endereco,
                            cidade: empresa.cidade
                        })
                })
            })
    }

    recoverExtintores(codEmpresa: string){
        console.log(codEmpresa)
        firebase.database().ref(`extintor/${codEmpresa}`)
            .once('value')
            .then((snapshot: any) => {
                snapshot.forEach((childSnapshot: any) => {
                    let localizacao
                    let subLocacao
                    let dataUltimaInspecao
                    let seloInMetro

                    if(childSnapshot.val().localizacao == null){
                        localizacao = null
                    }else{
                        localizacao = childSnapshot.val().localizacao
                    }

                    if(childSnapshot.val().subLocacao == null){
                        subLocacao = null
                    }else{
                        subLocacao = childSnapshot.val().subLocacao
                    }

                    if(childSnapshot.val().dataUltimaInspecao == null){
                        dataUltimaInspecao = null
                    }else{
                        dataUltimaInspecao = childSnapshot.val().dataUltimaInspecao
                    }

                    if(childSnapshot.val().seloInMetro == null){
                        seloInMetro = null
                    }else{
                        seloInMetro = childSnapshot.val().seloInMetro
                    }


                    let extintor = new Extintor(
                        childSnapshot.val().codigoProtecin,
                        codEmpresa,
                        childSnapshot.val().dataLancamento,
                        childSnapshot.val().tipo,
                        childSnapshot.val().capacidade,
                        childSnapshot.val().unidade,
                        localizacao,
                        childSnapshot.val().dataRecarga,
                        childSnapshot.val().dataTeste,
                        childSnapshot.val().dataProximaRecarga,
                        childSnapshot.val().dataProximoTeste,
                        dataUltimaInspecao,
                        seloInMetro,
                        childSnapshot.val().numeroCliente,
                        childSnapshot.val().numeroFabricante,
                        subLocacao,
                        null
                    )
                    console.log(extintor)
                    this.firestore.collection('empresas')
                        .doc(codEmpresa)
                        .collection('extintores')
                        .doc(extintor.codProtecin)
                        .set({
                            codigoProtecin: extintor.codProtecin,
                            codigoEmpresa: extintor.codigoEmpresa,
                            dataLancamento: extintor.dataLancamento,
                            tipo: extintor.tipo,
                            capacidade: extintor.capacidade,
                            unidade: extintor.unidade,
                            localizacao: extintor.localizacao,
                            dataRecarga: extintor.dataRecarga,
                            dataTeste: extintor.dataTeste,
                            dataProximaRecarga: extintor.dataProximaRecarga,
                            dataProximoTeste: extintor.dataProximoTeste,
                            dataUltimaInspecao: extintor.dataUltimaInspecao,
                            seloInMetro: extintor.seloInMetro,
                            numeroCliente: extintor.numeroCliente,
                            numeroFabricante: extintor.numeroFabricante,
                            subLocacao: extintor.subLocacao,
                            cadastradoPor: extintor.usuario 
                        })
                })
            })
    }

    recoverChecklist( codEmpresa: string ): void{
        firebase.database().ref(`checklist/${codEmpresa}`)
            .once('value')
            .then((snapshot: any) => {
                snapshot.forEach((childSnapshot: any) => {
                    console.log(childSnapshot.val());
                    console.log(childSnapshot.key);
                });
            });
    }
}
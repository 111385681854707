<div>
    <div class="row" >
        <mat-card class="card-cadastro" >
            <mat-card-title>Cadastrar Equipe</mat-card-title>
                <mat-vertical-stepper [linear]="isLinear" #stepper>
                <mat-step [stepControl]="formNivel">
                  <form [formGroup]="formNivel">
                    <ng-template matStepLabel>Selecione o Tipo de Cadastro</ng-template>
                    <mat-form-field>
                            <mat-select formControlName="nivel">
                                    <mat-option value="Admin">Administrador</mat-option>
                                    <mat-option value="moderador">Moderador</mat-option>
                                    <mat-option value="utilizador">Utilizador</mat-option>
                            </mat-select>        
                    </mat-form-field>
                    <div>
                      <button mat-button matStepperNext>Proximo</button>
                    </div>
                  </form>
                </mat-step>
                    <mat-step [stepControl]="formInitial">
                        <form [formGroup]="formInitial">
                             <ng-template matStepLabel>Digite um Nome</ng-template>
                            <mat-form-field>
                                <input matInput placeholder="Nome" formControlName="nome" required>
                            </mat-form-field>
                            <div>
                                <mat-form-field>
                                    <input matInput placeholder="E-mail" required type="email" formControlName="email">
                                </mat-form-field>
                            </div>
                        <div>
                                <button mat-button matStepperPrevious>Voltar</button>
                                <button mat-button matStepperNext>Proximo</button>
                        </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="formPass">
                    <form [formGroup]="formPass">
                        <ng-template matStepLabel>Digite uma Senha</ng-template>
                        <div>
                            <mat-form-field>
                                <input matInput placeholder="Senha" required formControlName="senha" type="password" >
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <input matInput placeholder="Confirme a senha" required formControlName="confSenha" type="password">
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-button (click)="cadastrarUsuario()" *ngIf="formPass.value.senha === formPass.value.confSenha && formPass.value.senha !== ''" >Cadastrar</button>
                        </div>
                    </form>
                    </mat-step>
                </mat-vertical-stepper>
        </mat-card>
    </div>
</div>
// Importações externas
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bd } from '../../../config/bd.service';
import { Relatorio } from '../../../model/relatorios.model';

@Component({
  selector: 'app-iventario',
  templateUrl: './iventario.component.html',
  styleUrls: ['./iventario.component.css']
})
export class IventarioComponent implements OnInit {

  constructor(
    private bd: Bd
  ) { }


  // Apresentar variáveis
  extintores: string;
  listExtintores: Relatorio[];
  dados: string = null;
  email: string = localStorage.getItem('email');
  codUnidade: string;
  nivel: string = localStorage.getItem('nivel');
  qtdExt: number;
  hoje: number = Date.now();
  subLocal: string;
  statusCheck: string;
  statusVenc: string;
  meses: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  anos: string[] = ['2018', '2019', '2020', '2021', '2022', '2023'];

  formulario: FormGroup = new FormGroup({
    codigoUnidade: new FormControl(null),
    checkSub:  new FormControl(null, [Validators.requiredTrue]),
    checkVenc: new FormControl(null, [Validators.requiredTrue]),
    codigoSublocacao: new FormControl(null),
    mesVencimento: new FormControl(null),
    anoVencimento: new FormControl(null),
  });

  ngOnInit(): void {
    this.consultaUnidade();
  }

  public listarInventario(): any{
    this.bd.consultaExtintores(this.formulario.value.codigoUnidade)
      .then((extintores: Relatorio[]): void => {
        this.listExtintores = extintores;
        this.qtdExt = this.listExtintores.length;
        if (this.formulario.value.checkSub === true){
          this.listarSublocacao();
        }else if (this.formulario.value.checkVenc === true && this.formulario.value.anoVencimento !== null){
          const periodo = this.formulario.value.mesVencimento + '/' + this.formulario.value.anoVencimento;
          this.listExtintores = this.listExtintores.filter(x => x.dataProximaRecarga === periodo);
          this.qtdExt = this.listExtintores.length;
        }
      });
  }

  public consultaEmpresaCodigo(): void{
    this.bd.consultaEmpresaCodigo(this.formulario.value.codigoUnidade)
    .then((dados: string) => {
      this.dados = dados;
    });
    this.listarInventario();
  }

  public consultaUnidade(): void{
    this.bd.consultaUnidades(this.email)
    .then((codUnidade: string) => {
      this.codUnidade = codUnidade;
    });
  }

  public consultaVencimento(): void{
    if (this.formulario.value.checkVenc === true){
      this.statusVenc = 'ativo';
    }else{
      this.statusVenc = 'inativo';
      this.listarInventario();
    }
  }

  public recuperaSublocacao(): void{
    this.bd.buscaSublocacao(this.formulario.value.codigoUnidade)
      .then((subLocal: string ) => {
        this.subLocal = subLocal;
      });
  }

  public listarSublocacao(): void{
    this.bd.consultaExtintoreSublocacao(
            this.formulario.value.codigoUnidade,
            this.formulario.value.codigoSublocacao)
        .then((extintores: Relatorio[]) => {
          this.listExtintores = extintores;
          this.qtdExt = extintores.length;
          if (this.formulario.value.checkVenc === true && this.formulario.value.anoVencimento !== null){
            const periodo: string = this.formulario.value.mesVencimento + '/' + this.formulario.value.anoVencimento;
            this.listExtintores = this.listExtintores.filter(x => x.dataProximaRecarga === periodo);
            this.qtdExt = this.listExtintores.length;
          }
        });
  }

  public recuperarListaVenc(): void{
    if (this.formulario.value.checkSub === true){
      console.log('abrindo sublocação');
      this.listarSublocacao();
    } else {
      this.listarInventario();
    }
  }

  fullNameColumn_calculateCellValue(rowData): string {
    return rowData.capacidade + ' ' + rowData.unidade;
  }

  onToolbarPreparing(e): void {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
        if (item.name === 'paging') {
          console.log('paginando');
        }
      });
    toolbarItems.push({
      location: 'before',
      widget: 'dxButton',
        options: { icon: 'print', onClick(): void{
          this.paging = false;
          setTimeout(() => { window.print(); }, 500);
        },
      },
    });
  }
}

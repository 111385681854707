<div class="margim">
  <!--Acesso Cliente -->

  <!--Cabeçalho-->
  <table class="table table1">
    <thead class="thead-primary">
      <tr>
        <th scope="col">
          <img class="logo d-flex align-items-center" src="../../../assets/logo-protecin.png" />
        </th>
        <th scope="col"></th>
        <th scope="col" *ngFor="let dado of dados">
          <small><b>{{dado.codigoInterno}} {{dado.razaoSocial}}</b></small><br />
          <small>{{dado.endereco}} - {{dado.cidade}}</small>
        </th>
        <th scope="col">
          <p>{{ data | date }}</p>
        </th>

      </tr>
    </thead>
    <tbody>
      <tr>

      </tr>
    </tbody>
  </table>

  <!--Fim Cabeçalho-->

  <!--TESTE DE PLANILHA-->
  <div id="grid">
    <form [formGroup]="formulario">
      <input id="select-prefix" type="text" class="form-control" (change)="consultaEmpresaCodigo()"
        placeholder="Digite o código da unidade" formControlName="codigoUnidade"
        *ngIf=" nivel === 'Admin' || nivel === 'Moderador' " />
      <select id="select-prefix" class="form-control" (change)="consultaEmpresaCodigo()"
        placeholder="Selecione a unidade" formControlName="codigoUnidade" *ngIf=" nivel === 'Cliente' ">
        <option value="" disabled selected>Selecione uma unidade...</option>
        <option *ngFor="let codigo of codUnidade" value="{{codigo.codigoInterno}}">{{codigo.codigoInterno}} -
          {{codigo.nomeFantasia}}</option>
      </select>

      <select class="form-control" id="select-prefix" formControlName="mesConsulta" aria-placeholder="Mês">
        <option value="jan" selected>Janeiro</option>
        <option value="fev">Fevereiro</option>
        <option value="mar">Março</option>
        <option value="abr">Abril</option>
        <option value="mai">Maio</option>
        <option value="jun">Junho</option>
        <option value="jul">Julho</option>
        <option value="ago">Agosto</option>
        <option value="set">Setembro</option>
        <option value="out">Outubro</option>
        <option value="nov">Novembro</option>
        <option value="dez">Dezembro</option>
      </select>
      <select class="form-control" id="select-prefix" formControlName="anoConsulta" aria-placeholder="Ano">
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option selected>2022</option>
        <option>2023</option>
      </select>
      <dx-check-box formControlName="checkSub" [value]="true" [width]="110" text="Sublocação"></dx-check-box>
      <dx-select-box *ngIf="formulario.get('checkSub').valid" id="select-prefix" formControlName="sublocacao"
        [dataSource]="sublocacao" placeholder="Selecione a Sublocação" [value]="prefix">
      </dx-select-box>
      <dx-button text="Consultar" [disabled]="false" (onClick)="checarSublocal()">
      </dx-button>
    </form>

    <dx-data-grid #grid (onToolbarPreparing)="onToolbarPreparing($event)" id="gridContainer" [dataSource]="item2"
      [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" [showBorders]="true">

      <dxo-paging name="paging" [pageSize]="10" [enabled]="false"></dxo-paging>

      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50]" [showNavigationButtons]="true">
      </dxo-pager>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-export [enabled]="true"></dxo-export>

      <dxi-column dataField="numeroCliente" dataType="text" width="60" [fixed]="true" caption="Nº"></dxi-column>
      <dxi-column dataField="numeroFabricante" dataType="text" caption="Nº Fab." width="50" [visible]="false">
      </dxi-column>
      <dxi-column dataField="localizacao" width="80" [visible]="false"></dxi-column>
      <dxi-column dataField="subLocacao" caption="Sublocação" width="80" [visible]="false"></dxi-column>
      <dxi-column dataField="tipo" width="60"></dxi-column>
      <dxi-column dataField="capacidade" width="80" [calculateCellValue]="fullNameColumn_calculateCellValue">
      </dxi-column>
      <dxi-column dataField="seloInMetro" width="80" [visible]="false"></dxi-column>
      <dxi-column dataField="dataProximoTeste" caption="Próx. Teste" width="80"></dxi-column>
      <dxi-column dataField="dataProximaRecarga" caption="Próx. Recarga" width="80"></dxi-column>
      <dxi-column dataField="dataUltimaInspecao" dataType="date" width="110"></dxi-column>
      <dxi-column dataField="colaborador" width="80"></dxi-column>
      <dxi-column dataField="demarcacaoSolo" cellTemplate="conformTemp" width="80"></dxi-column>
      <dxi-column dataField="sinalizacaoParede" caption="Sinalização" cellTemplate="conformTemp" width="80">
      </dxi-column>
      <dxi-column dataField="suporte" cellTemplate="conformTemp" width="80"></dxi-column>
      <dxi-column dataField="lacre" cellTemplate="conformTemp" width="80"></dxi-column>
      <dxi-column dataField="pressao" caption="Pressão" cellTemplate="conformTemp" width="80"></dxi-column>
      <dxi-column dataField="pesoValvula" caption="P. Válvula" [visible]="false" width="80"></dxi-column>
      <dxi-column dataField="pesoMedido" caption="P. Aferido" [visible]="false" width="80"></dxi-column>
      <dxi-column dataField="coeficientePesagem" caption="Prox. Teste" [visible]="false"></dxi-column>



      <dxo-summary>
        <dxi-total-item column="numeroCliente" summaryType="count" displayFormat="Total: {0}">
        </dxi-total-item>
        <dxi-total-item column="dataUltimaInspecao" summaryType="avg" displayFormat="Total: {0}">
        </dxi-total-item>
      </dxo-summary>

      <div *dxTemplate="let d of 'conformTemp'">
        <div *ngIf="d.value == 'Conforme'" class="conf">{{d.value}}</div>
        <div *ngIf="d.value == 'Não Conforme'" class="nconf">{{d.value}}</div>
        <div *ngIf="d.value == 'N/A'" class="naplic">{{d.value}}</div>
      </div>
    </dx-data-grid>

  </div>


  <!--CONTAGEM DE EQUIPAMNETOS INDIVIDUAIS-->
  <div class="row aba2 justify-content-center">

    <div class="col">
      <ul class="list-inline">
        <!--Extintores de AP-->
        <li class="list-inline-item" *ngIf="ap10l !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">AP 10l: {{ap_10l}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="ap75l !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">AP 75l: {{ap_75l}}</strong>
        </li>
        <!--Extintores de CO2-->
        <li class="list-inline-item" *ngIf="co22kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 2kg: {{co2_2kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="co24kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 4kg: {{co2_4kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="co26kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 6kg: {{co2_6kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="co210kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 10kg: {{co2_10kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="co225kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 25kg: {{co2_25kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="co245kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">CO2 45kg: {{co2_45kg}}</strong>
        </li>
        <!--Extintores de PQS BC-->
        <li class="list-inline-item" *ngIf="pqsBc2kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 2kg: {{pqsBc_2kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc4kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 4kg: {{pqsBc_4kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc6kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 6kg: {{pqsBc_6kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc8kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 8kg: {{pqsBc_8kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc12kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 12kg: {{pqsBc_12kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc20kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 20kg: {{pqsBc_20kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc30kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 30kg: {{pqsBc_30kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc50kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 50kg: {{pqsBc_50kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsBc100kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS BC 100kg: {{pqsBc_100kg}}</strong>
        </li>
        <!-- Linha PQS ABC-->
        <li class="list-inline-item" *ngIf="pqsABC2kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 2kg: {{pqsABC_2kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC4kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 4kg: {{pqsABC_4kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC45kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 4,5kg: {{pqsABC_45kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC6kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 6kg: {{pqsABC_6kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC8kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 8kg: {{pqsABC_8kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC9kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 9kg: {{pqsABC_9kg}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="pqsABC12kg !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">PQS ABC 12kg: {{pqsABC_12kg}}</strong>
        </li>
        <!--Espuma Mecanica-->
        <li class="list-inline-item" *ngIf="em9l !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">EM 9l: {{em_9l}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="em10l !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">EM 10l: {{em_10l}}</strong>
        </li>
        <li class="list-inline-item" *ngIf="em50l !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">EM 50l: {{em_50l}}</strong>
        </li>
        <!--Classe D-->
        <li class="list-inline-item" *ngIf="classeD !== 0">
          <img class="ext-img" src="../../../../assets/ext1.png" />
          <strong class="align-middle">Classe D: {{classeD}}</strong>
        </li>
      </ul>
    </div>
  </div>
  <!--FIM DA CONTAGEM DOS EQUIPAMENTOS INDIVIDUAIS-->
</div>
<div class="margim">

  <form [formGroup]="formulario" (ngSubmit)="listarRelatorio()">
    <div class="row">
      <div class="d-flex align-items-center">
        <label for="exampleFormControlSelect1">Selecione a Empresa</label>
      </div>
      <div class="col-sm-2">
        <div class="form-group">
          <select class="form-control"
              id="exampleFormControlSelect"
              (change)="consultaEmpresaCodigo()"
              formControlName="codigoUnidade"
              *ngIf=" nivel === 'Cliente' ">
            <option> Unidade </option>
            <option *ngFor="let codigo of codUnidade" 
                    value="{{codigo.codigoInterno}}">{{codigo.codigoInterno}} {{codigo.nomeFantasia}}</option>
          </select>

          <input class="form-control"
              type="text"
              placeholder="Default input"
              formControlName="codigoUnidade"
              (change)="consultaEmpresaCodigo()"
              *ngIf=" nivel === 'Admin' || nivel === 'moderador'">
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <select class="form-control" id="mesConsulta" formControlName="mesConsulta">
            <option>jan</option>
            <option>fev</option>
            <option>mar</option>
            <option>abr</option>
            <option>mai</option>
            <option>jun</option>
            <option>jul</option>
            <option>ago</option>
            <option>set</option>
            <option>out</option>
            <option>nov</option>
            <option>dez</option>
          </select>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <select class="form-control" id="anoConsulta" formControlName="anoConsulta">
            <option>2018</option>
          </select>
        </div>
      </div>
      <div class="col-sm-2">
        <button type="submit" class="btn btn-primary">Consultar</button>
      </div>
    </div>
  </form>

  <div>
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-center">
        <h6>Retirada de equipamentos</h6>
      </div>
    </div>

    <table class="table">
      <thead class="thead-primary">
        <tr>
          <th scope="col">
            <img class="logo d-flex align-items-center" src="../../../../assets/logo-protecin.png"/>
          </th>

          <th scope="col" *ngFor="let dado of dados">
            <small><b>{{dado.codigoInterno}} {{dado.razaoSocial}}</b></small> <br/>
            <small>{{dado.endereco}} - {{dado.cidade}}</small>
          </th>

          <th scope="col">{{ data | date }}</th>
        </tr>
      </thead>
    </table>

    <table class="table">
      <thead class="thead-primary">
        <tr>
          <th scope="col">Nº Cliente</th>
          <th scope="col">Tipo</th>
          <th scope="col">Capacidade</th>
          <th scope="col">nº Fabricante</th>
          <th scope="col">Colaborador</th>
          <th scope="col">Data da Retirada</th>
        </tr>
      </thead>
      <tbody *ngFor="let retirada of retiradas">
        <tr>
          <th scope="row">{{retirada.numeroCliente}}</th>
          <td>{{retirada.tipo}}</td>
          <td>{{retirada.capacidade}} {{retirada.unidade}}</td>
          <td>{{retirada.numeroFabricante}}</td>
          <td>{{retirada.colaborador}}</td>
          <td>{{retirada.dataLancamento}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6">Quantidade de extintores retirados: {{qtdExt}}</td>
        </tr>
      </tfoot>
    </table>
  </div>


</div>
